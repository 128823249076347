import React from 'react';
import { AddAddress } from './AddAddress';
import { Link } from 'react-router-dom';

export const Furtherproced = () => {
	return (
		<>
			<button
				className='mt-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-full md:w-80'
				onClick={() => document.getElementById('confirm-address').showModal()}
			>
				Proceed to checkout
			</button>
			<dialog
				id='confirm-address'
				className='modal'
			>
				<div className='modal-box w-11/12 max-w-5xl bg-white'>
					<form method='dialog'>
						{/* if there is a button in form, it will close the modal */}
						<button className='btn btn-sm btn-ghost absolute right-2 top-2 border-0'>
							✕
						</button>
					</form>
					<div className='font-bold text-lg flex justify-center items-center'>
						My Delivery Address
					</div>
					<div className='flex flex-col border-2 border-black rounded-2xl max-w-full md:max-w-fit p-8 gap-4'>
						<h1 className='font-bold text-lg'>Amna</h1>
						<p>12345678</p>
						<p>
							khdaksgdagskduagehmnbkaushdkajsbxsjbchkas <br />
							gdhxbjhfdkajsdlihejrhdcdudbjzdszbkjcdmcskjf
						</p>
						<p>Home</p>
					</div>

					<div className='modal-action'>
						<form method='dialog'>
							<AddAddress />
							<Link to={'/shipping'}>
								<button className='mx-5 my-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-full md:w-80'>
									Confirm
								</button>
							</Link>
						</form>
					</div>
				</div>
			</dialog>
		</>
	);
};
