import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Cart } from '../../components/Cart/Cart';
export const CartPage = () => {
	return (
		<>
			<Navbar />
			<Cart />
			<Footer />
		</>
	);
};
