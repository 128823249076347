import React, { useState } from 'react';
import { MainHome } from '../MainHome/MainHome';
import DropDwn from './DropDwn';
import { AboutUs } from '../Aboutus/AboutUs';
import { QualityController } from '../QualityController/QualityController';
import { SellerHome } from '../SellerHome/SellerHome';

export const TopBar = () => {
	const [activeTab, setActiveTab] = useState('Home');

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	return (
		<>
			<div className='flex flex-col sm:flex-row md:flex-row sm:items-center md:items-center top-bar bg-gray-900 text-black'>
				<DropDwn />

				{[
					'Home',
					'Become a seller',
					'Become an Quality Controller',
					'About us',
				].map((tab) => (
					<button
						key={tab}
						className={`inline-block p-4 rounded-t-lg transition-all duration-1000 ease-out ${
							activeTab === tab
								? 'text-[#040403]'
								: 'hover:text-gray-600 text-black sm:text-sm md:text-base'
						}`}
						onClick={() => handleTabClick(tab)}
					>
						{tab.charAt(0).toUpperCase() + tab.slice(1)}
					</button>
				))}
			</div>

			{/* Tab Content */}

			<div className='bg-white transition-all duration-1000 ease-out'>
				{activeTab === 'Home' && (
					<>
						<MainHome />
					</>
				)}
				{activeTab === 'Become a seller' && (
					<>
						<SellerHome />
					</>
				)}
				{activeTab === 'Become an Quality Controller' && (
					<>
						<QualityController />
					</>
				)}
				{activeTab === 'About us' && (
					<>
						<AboutUs />
					</>
				)}
			</div>
		</>
	);
};
