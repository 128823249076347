import React, { useEffect, useState } from 'react';
import { Rack } from './Rack';
import { OurQC } from './OurQC';
import { Expo } from './Expo';
import { SellerForms } from './SellerForms';
import { QCform } from '../QualityController/QCform';

function TabSeller({ type }) {
	const [activeTab, setActiveTab] = useState('');

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	useEffect(() => {
		if (type === 'seller') {
			setActiveTab('seller');
		} else if (type === 'qualitycontroller') {
			setActiveTab('qualitycontroller');
		}
	}, [type]);

	return (
		<>
			<div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row mx-4 sm:mx-4 md:mx-4 lg:mx-4 xl:mx-4'>
				{['Expo Schedule', 'Our Quality Controller', 'Racks'].map((tab) => (
					<button
						key={tab}
						className={`inline-block p-4 rounded-t-lg transition-all duration-300 ${
							activeTab === tab
								? 'text-[#ffd700] transform scale-110'
								: 'hover:text-gray-600 text-black sm:text-sm md:text-base'
						}`}
						onClick={() => handleTabClick(tab)}
					>
						{tab.charAt(0).toUpperCase() + tab.slice(1)}
					</button>
				))}
				{type === 'seller' && (
					<button
						className={`inline-block p-4 rounded-t-lg transition-all duration-300 ${
							activeTab === 'seller'
								? 'text-[#ffd700] transform scale-110'
								: 'hover:text-gray-600 text-black sm:text-sm md:text-base'
						}`}
						onClick={() => handleTabClick('seller')}
					>
						Seller Forms
					</button>
				)}
				{type === 'qualitycontroller' && (
					<button
						className={`inline-block p-4 rounded-t-lg transition-all duration-300 ${
							activeTab === 'qualitycontroller'
								? 'text-[#ffd700] transform scale-110'
								: 'hover:text-gray-600 text-black sm:text-sm md:text-base'
						}`}
						onClick={() => handleTabClick('qualitycontroller')}
					>
						QC Form
					</button>
				)}
			</div>

			{/* Tab Content */}

			<div className='bg-white'>
				{activeTab === 'Expo Schedule' && <Expo />}
				{activeTab === 'Our Quality Controller' && <OurQC />}
				{activeTab === 'Racks' && <Rack />}
				{activeTab === 'seller' && <SellerForms />}
				{activeTab === 'qualitycontroller' && <QCform />}
			</div>
		</>
	);
}

export default TabSeller;
