import React, { useState } from 'react';

const Submenu = ({ items, isOpen, onToggle, name, level }) => {
	return (
		<div
			className={`dropdown dropdown-right submenu submenu-${level}`}
			tabIndex={1}
		>
			<div
				tabIndex={1}
				role='button'
				onClick={onToggle}
			>
				{name}
			</div>
			<ul
				tabIndex={1}
				className={`dropdown-content ${
					isOpen ? '' : 'hidden'
				} z-[1] menu p-2 shadow bg-white text-black rounded-box w-52`}
			>
				{items.map((item, index) => (
					<li key={index}>
						{item.subItems ? (
							<Submenu
								items={item.subItems}
								isOpen={isOpen && index === 0}
								onToggle={() => onToggle(index)}
								name={item.itemName}
								level={level + 1}
							/>
						) : (
							<a>{item}</a>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

const DropDwn = () => {
	const menuData = [
		{
			itemName: 'Groceries & Pets',
			subItems: [
				'Fresh Produce',
				'Beverages',
				'Food Staples',
				'Breakfast & Snacks',
				'Household Stuff',
				'Frozen Food',
				'Cat food',
				'Dog Food',
				'Fish Food',
			],
		},
		{
			itemName: 'Mother & Baby',
			subItems: ['Diapers', 'Baby Care', 'Mother Care'],
		},
		{
			itemName: 'Men’s Fashion',
			subItems: ['shirt', 'Pants', 'Shoes', 'Accessories'],
		},
		{
			itemName: 'Women’s Fashion',
			subItems: ['shirt', 'Pants', 'Shoes', 'Accessories'],
		},
		{
			itemName: 'Home & Lifestyle',
			subItems: ['Furniture', 'Kitchen', 'Home Decor', 'Bedding', 'Bath'],
		},
		{
			itemName: 'Electronic Accessories',
			subItems: [
				'Mobile Accessories',
				'Laptop Accessories',
				'Camera Accessories',
			],
		},
		{
			itemName: 'Electronic Devices',
			subItems: ['Mobile', 'Laptop', 'Camera', 'Tablet'],
		},
		{
			itemName: 'TV & Home Appliances',
			subItems: ['TV', 'AC', 'Washing Machine', 'Refrigerator'],
		},
		{
			itemName: 'Sports & Outdoor',
			subItems: ['Sports', 'Outdoor'],
		},
		{
			itemName: 'Automotive & motorbike',
			subItems: ['Automotive', 'Motorbike', 'Motorbike Accessories'],
		},
		{
			itemName: 'Watches, Bags & Jewelry',
			subItems: ['Watches', 'Bags', 'Jewelry'],
		},
		{
			itemName: 'Digital Services',
			subItems: ['Digital Services', 'Digital Services'],
		},
	];

	const subMenuData = [
		{
			itemName: 'Groceries & Pets',
			subItems: [
				{
					itemName: 'Fresh Produce',
					subItems: ['Item 1', 'Item 2', 'Item 3'],
				},
				{
					itemName: 'Beverages',
					subItems: ['Item 4', 'Item 5', 'Item 6'],
				},
				{
					itemName: 'Food Staples',
					subItems: ['Item 7', 'Item 8', 'Item 9'],
				},
				{
					itemName: 'Breakfast & Snacks',
					subItems: ['Item 10', 'Item 11', 'Item 12'],
				},
				{
					itemName: 'Household Stuff',
					subItems: ['Item 13', 'Item 14', 'Item 15'],
				},
				{
					itemName: 'Frozen Food',
					subItems: ['Item 16', 'Item 17', 'Item 18'],
				},
				{
					itemName: 'Cat food',
					subItems: ['Item 19', 'Item 20', 'Item 21'],
				},
				{
					itemName: 'Dog Food',
					subItems: ['Item 22', 'Item 23', 'Item 24'],
				},
				{
					itemName: 'Fish Food',
					subItems: ['Item 25', 'Item 26', 'Item 27'],
				},
			],
		},
	];
	const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

	const handleSubmenuToggle = (index) => {
		setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
	};

	return (
		<>
			<div className='dropdown'>
				<div
					tabIndex={0}
					role='button'
					className='btn m-1 bg-[#ffd700] hover:bg-[#ffd700] rounded-full'
				>
					<div className='flex gap-2 rounded-full hover:bg-[#ffd700] text-black items-center justify-center'>
						<div>
							<svg
								width='16'
								height='12'
								viewBox='0 0 16 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0 11.27V10.27H16V11.27H0ZM0 6.49998V5.49998H16V6.49998H0ZM0 1.72998V0.72998H16V1.72998H0Z'
									fill='black'
								/>
							</svg>
						</div>
						<div className='text-black text-lg'>Categories</div>
						<div>
							<svg
								width='21'
								height='13'
								viewBox='0 0 21 13'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M2.31489 0.0770578L0.217627 0.0859123C0.0358464 0.0866798 -0.0637209 0.28115 0.0480714 0.411723L9.64903 11.7319C9.73025 11.828 9.83406 11.9057 9.95255 11.9589C10.071 12.0122 10.2011 12.0395 10.3327 12.039C10.4643 12.0384 10.5941 12.01 10.7122 11.9557C10.8302 11.9015 10.9333 11.823 11.0137 11.7261L20.5188 0.325297C20.6294 0.191264 20.5283 0.000161792 20.3465 0.000929268L18.2492 0.00978382C18.1163 0.0103451 17.989 0.0688444 17.908 0.16495L10.3224 9.26939L2.6574 0.229338C2.5756 0.133919 2.44784 0.0764965 2.31489 0.0770578Z'
									fill='#1E1E1E'
								/>
							</svg>
						</div>
					</div>
				</div>
				<ul
					tabIndex={0}
					className='dropdown-content z-[1] menu p-2 shadow rounded-box w-52 bg-white text-black'
				>
					{menuData.map((menuItem, index) => (
						<li key={index}>
							<Submenu
								items={menuItem.subItems}
								isOpen={openSubmenuIndex === index}
								onToggle={() => handleSubmenuToggle(index)}
								name={menuItem.itemName}
							/>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default DropDwn;
