import React from 'react';
import banner1 from '../../assets/images/main1.png';
import banner2 from '../../assets/images/main2.png';

export const Banner = () => {
	return (
		<div className='flex flex-col sm:flex-row md:flex-row gap-4 sm:mx-4 md:mx-4 justify-center text-center'>
			<figure className='my-3'>
				<img
					src={banner1}
					alt='Shoes'
					className='w-full sm:max-w-xs md:max-w-md'
				/>
			</figure>
			<figure className='my-3'>
				<img
					src={banner2}
					alt='Shoes'
					className='w-full sm:max-w-xs md:max-w-md'
				/>
			</figure>
		</div>
	);
};
