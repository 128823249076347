// import React from 'react';

// export const Card = () => {
// 	return (
// 		<div className='card w-64  shadow-xl text-black'>
// 			<figure>
// 				<img
// 					src='https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg'
// 					alt='Shoes'
// 				/>
// 			</figure>
// 			<div className='card-body'>
// 				<div className='flex items-center justify-between'>
// 					<h2 className='card-title'>Airlight</h2>
// 					<div className='nav-heart'>
// 						<svg
// 							xmlns='http://www.w3.org/2000/svg'
// 							fill='none'
// 							viewBox='0 0 24 24'
// 							className='inline-block w-8 h-8 stroke-current'
// 						>
// 							<path
// 								strokeLinecap='round'
// 								strokeLinejoin='round'
// 								strokeWidth='2'
// 								d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
// 							></path>
// 						</svg>
// 					</div>
// 				</div>
// 				<div className='flex items-center justify-between'>
// 					<div className='flex gap-1'>
// 						<svg
// 							width='24'
// 							height='23'
// 							viewBox='0 0 24 23'
// 							fill='none'
// 							xmlns='http://www.w3.org/2000/svg'
// 						>
// 							<path
// 								d='M23.2243 8.09896L16.0296 7.04962L12.8133 0.506195C12.7255 0.32704 12.581 0.182009 12.4024 0.0938536C11.9547 -0.127957 11.4106 0.0568852 11.1868 0.506195L7.97055 7.04962L0.77582 8.09896C0.577461 8.12739 0.396105 8.22124 0.257255 8.36342C0.0893918 8.53657 -0.0031083 8.76951 7.97568e-05 9.01106C0.00326781 9.25261 0.101883 9.48301 0.274257 9.65163L5.47974 14.7448L4.24992 21.9365C4.22108 22.1038 4.23953 22.2759 4.30317 22.4332C4.36681 22.5905 4.47311 22.7268 4.60999 22.8266C4.74688 22.9263 4.90889 22.9856 5.07764 22.9977C5.24639 23.0098 5.41514 22.9742 5.56475 22.8949L12.0001 19.4995L18.4354 22.8949C18.6111 22.9887 18.8151 23.02 19.0106 22.9859C19.5037 22.9006 19.8352 22.4314 19.7502 21.9365L18.5204 14.7448L23.7259 9.65163C23.8675 9.51229 23.9611 9.33029 23.9894 9.13123C24.0659 8.63358 23.7202 8.17289 23.2243 8.09896Z'
// 								fill='#FFD700'
// 							/>
// 						</svg>
// 						<p>4/5</p>
// 					</div>
// 					<div className='text-lg font-bold'>1k sold</div>
// 				</div>
// 				<div className='flex card-actions justify-start'>
// 					<div className='text-lg font-bold'>Rs. 3000</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

import React from 'react';
import heart from '../../assets/images/heart.png';

export const Card = () => {
	return (
		<div className='card w-full sm:w-64 md:w-64 lg:w-64 xl:w-64  shadow-xl text-black'>
			<figure>
				<img
					src='https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg'
					alt='Shoes'
					className='w-full h-48 object-cover'
				/>
			</figure>
			<div className='card-body p-4'>
				<div className='flex items-center justify-between'>
					<h2 className='card-title text-lg sm:text-base md:text-base lg:text-base xl:text-base'>
						Airlight
					</h2>
					<div className='nav-heart'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							className='inline-block w-8 h-8 stroke-current'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
							></path>
						</svg>
					</div>
				</div>
				<div className='flex items-center justify-between'>
					<div className='flex gap-1'>
						<svg
							width='24'
							height='23'
							viewBox='0 0 24 23'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M23.2243 8.09896L16.0296 7.04962L12.8133 0.506195C12.7255 0.32704 12.581 0.182009 12.4024 0.0938536C11.9547 -0.127957 11.4106 0.0568852 11.1868 0.506195L7.97055 7.04962L0.77582 8.09896C0.577461 8.12739 0.396105 8.22124 0.257255 8.36342C0.0893918 8.53657 -0.0031083 8.76951 7.97568e-05 9.01106C0.00326781 9.25261 0.101883 9.48301 0.274257 9.65163L5.47974 14.7448L4.24992 21.9365C4.22108 22.1038 4.23953 22.2759 4.30317 22.4332C4.36681 22.5905 4.47311 22.7268 4.60999 22.8266C4.74688 22.9263 4.90889 22.9856 5.07764 22.9977C5.24639 23.0098 5.41514 22.9742 5.56475 22.8949L12.0001 19.4995L18.4354 22.8949C18.6111 22.9887 18.8151 23.02 19.0106 22.9859C19.5037 22.9006 19.8352 22.4314 19.7502 21.9365L18.5204 14.7448L23.7259 9.65163C23.8675 9.51229 23.9611 9.33029 23.9894 9.13123C24.0659 8.63358 23.7202 8.17289 23.2243 8.09896Z'
								fill='#FFD700'
							/>
						</svg>
						<p className='text-sm'>4/5</p>
					</div>
					<div className='text-base font-bold'>1k sold</div>
				</div>
				<div className='flex card-actions justify-start'>
					<div className='text-lg font-bold'>Rs. 3000</div>
				</div>
			</div>
		</div>
	);
};
