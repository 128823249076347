import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

export const Navbar = () => {
	return (
		<div className='navbar navbar-style px-28'>
			<div className='navbar-start'>
				<div className='dropdown bg-white text-black'>
					<div
						tabIndex={0}
						role='button'
						className='btn btn-ghost lg:hidden'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M4 6h16M4 12h8m-8 6h16'
							/>
						</svg>
					</div>
					<ul
						tabIndex={0}
						className='menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white text-black rounded-box w-52'
					>
						<li>
							<Link to={'/login'}>
								<button> Login</button>
							</Link>
						</li>
						<li>
							<Link to={'/signup'}>
								<button>Signup</button>
							</Link>
						</li>
						<li>
							<Link to={'/cart'}>cart</Link>
						</li>
						<li>
							<Link to={'/favourite'}>favourite</Link>
						</li>
					</ul>
				</div>
				<Link to={'/'}>
					<img
						src={require('../../assets/images/logo.png')}
						alt='logo'
						className='navbar-logo'
					/>
				</Link>
			</div>
			<div className='navbar-center hidden lg:flex'>
				<div className='join'>
					<input
						className='input input-bordered join-item w-96'
						placeholder='search for items, brands and inspiration...'
					/>
					<button className='btn join-item search-btn'> Search</button>
				</div>
			</div>
			<div className='navbar-end hidden lg:flex'>
				<div className='nav-right'>
					<div>
						<Link to={'/login'}>
							<button> Login</button>
						</Link>
					</div>
					<div>
						<Link to={'/signup'}>
							<button>Signup</button>
						</Link>
					</div>

					<div className='nav-heart'>
						<Link to={'/favourite'}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								className='inline-block w-8 h-8 stroke-current'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
								></path>
							</svg>
						</Link>
					</div>
					<div className='indicator'>
						<Link to={'/cart'}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'
								/>
							</svg>
							<span className='badge badge-sm indicator-item'>8</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
