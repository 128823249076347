import React from 'react';
import { SimilarProduct } from './SimilarProduct';
import { BuyerQA } from './BuyerQA';
import { Discription } from './Discription';
import { Rating } from './Rating';
import { Product } from './Product';

export const ProductDetail = () => {
	return (
		<>
			{' '}
			<Product />
			<Discription />
			<Rating />
			<BuyerQA />
			<SimilarProduct />
		</>
	);
};
