// import React from 'react';
// import { CarouselBottem } from '../MainHome/CarouselBottem';
// import dummyimg from '../../assets/images/sampleimg.png';
// import { Link } from 'react-router-dom';

// export const Cart = () => {
// 	return (
// 		<>
// 			<div className='px-40 text-xl font-bold text-black bg-[#FFD700] py-5 '>
// 				Your Products In Cart
// 			</div>

// 			<div className='min-h-lvh flex mx-40 text-black justify-center  gap-10 '>
// 				<div className='flex flex-col  my-10 gap-5 p-5 h-fit'>
// 					<div className='border-2 rounded-lg p-5 flex justify-between'>
// 						<div className='flex gap-5'>
// 							<label>
// 								<input
// 									type='checkbox'
// 									className='checkbox'
// 								/>
// 							</label>
// 							<p>SELECT ALL (1 ITEM(S))</p>
// 						</div>
// 						<div>
// 							<svg
// 								width='37'
// 								height='37'
// 								viewBox='0 0 37 37'
// 								fill='none'
// 								xmlns='http://www.w3.org/2000/svg'
// 							>
// 								<path
// 									d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
// 									fill='black'
// 								/>
// 							</svg>
// 						</div>
// 					</div>
// 					<div className='flex items-center  gap-10'>
// 						<div className='overflow-x-auto '>
// 							<table className='table text-black border-2 rounded-lg'>
// 								{/* head */}
// 								<thead>
// 									<tr className='text-black'>
// 										<th>
// 											<label>
// 												<input
// 													type='checkbox'
// 													className='checkbox'
// 												/>
// 											</label>
// 										</th>
// 										<th></th>
// 										<th>sr#</th>
// 										<th>Product Name</th>
// 										<th>Price</th>
// 										<th>Quantity</th>
// 										<th>
// 											<div className='nav-heart'>
// 												<svg
// 													xmlns='http://www.w3.org/2000/svg'
// 													fill='none'
// 													viewBox='0 0 24 24'
// 													className='inline-block w-8 h-8 stroke-current'
// 												>
// 													<path
// 														strokeLinecap='round'
// 														strokeLinejoin='round'
// 														strokeWidth='2'
// 														d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
// 													></path>
// 												</svg>
// 											</div>
// 										</th>
// 										<th>
// 											<svg
// 												width='37'
// 												height='37'
// 												viewBox='0 0 37 37'
// 												fill='none'
// 												xmlns='http://www.w3.org/2000/svg'
// 											>
// 												<path
// 													d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
// 													fill='black'
// 												/>
// 											</svg>
// 										</th>
// 									</tr>
// 								</thead>
// 								<tbody>
// 									{/* row 1 */}
// 									<tr>
// 										<th>
// 											<label>
// 												<input
// 													type='checkbox'
// 													className='checkbox'
// 												/>
// 											</label>
// 										</th>
// 										<td>
// 											<div className='flex items-center gap-3'>
// 												<div className='avatar'>
// 													<div className='mask mask-squircle w-12 h-12'>
// 														<img
// 															src={dummyimg}
// 															alt=''
// 														/>
// 													</div>
// 												</div>
// 											</div>
// 										</td>
// 										<td>1</td>
// 										<td>Product 1</td>
// 										<td>Rs. 277</td>
// 										<td>1</td>
// 									</tr>
// 								</tbody>
// 								{/* foot */}
// 								<tfoot>
// 									<tr className='text-black'>
// 										<th>total rs 277</th>
// 									</tr>
// 								</tfoot>
// 							</table>
// 						</div>
// 					</div>
// 				</div>
// 				<div className='flex flex-col border-2 rounded-lg my-10 gap-5 p-5 h-fit'>
// 					<div>Order Summary</div>
// 					<div className='flex items-center justify-between gap-10'>
// 						<div>Subtotal (1 items) </div>
// 						<div> Rs. 277</div>
// 					</div>
// 					<div className='flex items-center justify-between gap-10'>
// 						<div>shipping fee </div>
// 						<div> Rs. 277</div>
// 					</div>

// 					<div className='flex items-center justify-between gap-10'>
// 						<div>Total: </div>
// 						<div> Rs. 406</div>
// 					</div>
// 					<Link to='/checkout'>
// 						<button className='mt-5 py-4 px-2 rounded-lg  bg-[#ffd700] border-black w-80'>
// 							Proceed to checkout
// 						</button>
// 					</Link>
// 					{/* <button className='mt-5 py-4 px-2 rounded-lg  bg-[#ffd700] border-black w-80'>
// 						Proceed to checkout
// 					</button> */}
// 				</div>
// 			</div>

// 			<CarouselBottem />
// 		</>
// 	);
// };

import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import { Link } from 'react-router-dom';

export const Cart = () => {
	return (
		<>
			<div className='px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Your Products In Cart
			</div>

			<div className='min-h-lvh flex flex-col md:flex-row mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 text-black justify-center gap-6 md:gap-10'>
				<div className='flex flex-col md:flex-col my-6 gap-5 p-5 h-fit w-full md:w-2/3'>
					<div className='border-2 rounded-lg p-5 flex justify-between w-full'>
						<div className='flex gap-5'>
							<label>
								<input
									type='checkbox'
									className='checkbox'
								/>
							</label>
							<p>SELECT ALL (1 ITEM(S))</p>
						</div>
						<div>
							<svg
								width='37'
								height='37'
								viewBox='0 0 37 37'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
									fill='black'
								/>
							</svg>
						</div>
					</div>
					<div className='flex items-center gap-6 overflow-x-auto w-full'>
						<table className='table text-black border-2 rounded-lg'>
							{/* head */}
							<thead>
								<tr className='text-black'>
									<th>
										<label>
											<input
												type='checkbox'
												className='checkbox'
											/>
										</label>
									</th>
									<th></th>
									<th>sr#</th>
									<th>Product Name</th>
									<th>Price</th>
									<th>Quantity</th>
									<th>
										<div className='nav-heart'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												className='inline-block w-8 h-8 stroke-current'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
												></path>
											</svg>
										</div>
									</th>
									<th>
										<svg
											width='37'
											height='37'
											viewBox='0 0 37 37'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
												fill='black'
											/>
										</svg>
									</th>
								</tr>
							</thead>
							<tbody>
								{/* row 1 */}
								<tr>
									<th>
										<label>
											<input
												type='checkbox'
												className='checkbox'
											/>
										</label>
									</th>
									<td>
										<div className='flex items-center gap-3'>
											<div className='avatar'>
												<div className='mask mask-squircle w-12 h-12'>
													<img
														src={dummyimg}
														alt=''
													/>
												</div>
											</div>
										</div>
									</td>
									<td>1</td>
									<td>Product 1</td>
									<td>Rs. 277</td>
									<td>1</td>
								</tr>
							</tbody>
							{/* foot */}
							<tfoot>
								<tr className='text-black'>
									<th>total rs 277</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>

				<div className='flex flex-col border-2 rounded-lg my-10 gap-5 p-5 h-fit w-full md:w-1/3'>
					<div>Order Summary</div>
					<div className='flex items-center justify-between gap-5'>
						<div>Subtotal (1 item) </div>
						<div> Rs. 277</div>
					</div>
					<div className='flex items-center justify-between gap-5'>
						<div>Shipping Fee </div>
						<div> Rs. 277</div>
					</div>

					<div className='flex items-center justify-between gap-5'>
						<div>Total: </div>
						<div> Rs. 406</div>
					</div>
					<Link to='/checkout'>
						<button className='mt-5 py-3 px-2 rounded-lg bg-[#ffd700] border-black w-full'>
							Proceed to Checkout
						</button>
					</Link>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
