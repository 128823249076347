import React from 'react';
import { Card } from '../MainHome/Card';
import { Link } from 'react-router-dom';

export const SimilarProduct = () => {
	return (
		<div className='flex flex-col sm:flex-col md:flex-col my-5 sm:mx-4 '>
			<div className='text-black text-2xl font-bold bg-[#ffd700] py-5 md:mx-40 px-2'>
				Similar Products for you to consider
			</div>
			<div className='flex flex-wrap gap-4 my-3 justify-center'>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
			</div>
			<div className='flex flex-wrap gap-4 my-3 justify-center'>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
			</div>
			<div className='flex flex-wrap gap-4 my-3 justify-center'>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
			</div>

			<div className='flex items-center justify-center'>
				<button className='bg-[#ffd700] text-black font-bold py-3 px-6 rounded-lg w-80'>
					Load More
				</button>
			</div>
		</div>
	);
};
