import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Order } from '../../components/Order/Order';

export const OrderPage = () => {
	return (
		<>
			<Navbar />
			<Order />
			<Footer />
		</>
	);
};
