import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { ConfirmedCancel } from '../../components/Order/ConfirmedCancel';
export const CancelConfirmPage = () => {
	return (
		<>
			<Navbar />
			<ConfirmedCancel />
			<Footer />
		</>
	);
};
