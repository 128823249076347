// import React from 'react';
// import { CarouselBottem } from '../MainHome/CarouselBottem';
// import dummyimg from '../../assets/images/sampleimg.png';
// import tick from '../../assets/images/tick.png';

// export const ConfirmedCancel = () => {
// 	return (
// 		<>
// 			<div className='px-40 text-xl font-bold text-black bg-[#FFD700] py-5 '>
// 				Request Cancellation
// 			</div>
// 			<div className=' flex flex-col mx-40 text-black  gap-10 '>
// 				<div className='flex flex-col  my-10 gap-5 p-5 h-fit w-full'>
// 					<div className='border-2 rounded-lg p-5 flex justify-center gap-10'>
// 						<img
// 							src={tick}
// 							alt=''
// 							className='bg-[#00A919] rounded-full'
// 						/>
// 						<p className='text-xl font-medium'>
// 							Your Cancellation was successful
// 						</p>
// 					</div>
// 				</div>
// 				<div className='flex flex-col gap-5 p-5 h-fit w-full'>
// 					<div className='border-2 rounded-lg p-5 flex items-start '>
// 						<div className='px-10'>
// 							<img
// 								src={dummyimg}
// 								alt=''
// 							/>
// 						</div>
// 						<div
// 							className='flex
//                           justify-between'
// 						>
// 							<p>
// 								M10 & M90 & i12 black Double Wireless 2 Airpods Bluetooth Hand
// 								Free with Power Bank Earphones White bluetooth ear phone for all
// 								cell phones
// 							</p>
// 							<p className='px-20'>Qty: 1</p>
// 						</div>
// 					</div>
// 				</div>

// 				<div className='flex justify-center'>
// 					<button className='bg-[#FFD700] text-black py-3 px-10 rounded-lg'>
// 						View Cancel Details
// 					</button>
// 				</div>
// 			</div>

// 			<CarouselBottem />
// 		</>
// 	);
// };

import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import tick from '../../assets/images/tick.png';
import { Link } from 'react-router-dom';

export const ConfirmedCancel = () => {
	return (
		<>
			<div className='px-5 sm:px-10 md:px-20 lg:px-40 text-xl font-bold text-black bg-[#FFD700] py-5 '>
				Request Cancellation
			</div>
			<div className='flex flex-col mx-5 sm:mx-10 md:mx-20 lg:mx-40 text-black gap-10'>
				<div className='flex flex-col my-5 gap-5 p-5 h-fit w-full'>
					<div className='border-2 rounded-lg p-5 flex justify-center items-center gap-10'>
						<img
							src={tick}
							alt=''
							className='bg-[#00A919] rounded-full'
						/>
						<p className='text-xl font-medium'>
							Your Cancellation was successful
						</p>
					</div>
				</div>
				<div className='flex flex-col gap-5 p-5 h-fit w-full'>
					<div className='border-2 rounded-lg p-5 flex flex-col md:flex-row lg:flex-row items-center'>
						<div className='px-5 sm:px-10'>
							<img
								src={dummyimg}
								alt=''
								className='w-20 h-20 object-cover'
							/>
						</div>
						<div className='flex justify-between w-full sm:w-auto'>
							<p className='w-2/3 sm:w-4/5'>
								M10 & M90 & i12 black Double Wireless 2 Airpods Bluetooth Hand
								Free with Power Bank Earphones White bluetooth ear phone for all
								cell phones
							</p>
							<p className='px-5 sm:px-10'>Qty: 1</p>
						</div>
					</div>
				</div>

				<div className='flex justify-center'>
					<Link to='/cancel-details'>
						<button className='bg-[#FFD700] text-black py-3 px-10 rounded-lg'>
							View Cancel Details
						</button>
					</Link>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
