import React from 'react';
import dummyimage from '../../assets/images/sampleimg.png';
import thumbup from '../../assets/images/thumbup.png';
import thumbdown from '../../assets/images/thumbDown.png';
import Reviewfivestar from '../../assets/images/Reviewfivestar.png';
export const Reviews = () => {
	return (
		<>
			<div className='flex flex-col text-black gap-5'>
				<div className='flex justify-between'>
					<div className='flex'>
						<p className='font-bold'>Muhammad H.</p>
						<img
							src={Reviewfivestar}
							alt=''
						/>
					</div>
					<div>10 months ago </div>
				</div>
				<div>
					Lorem ipsum dolor sit amet consectetur. Id ultricies interdum
					venenatis habitant pulvinar enim elementum. Pharetra sit eu nunc dolor
					tempus massa feugiat ut tincidunt. Arcu semper nisl risus eu. At odio
					dolor pellentesque nullam sagittis at in velit tellus. Tincidunt leo
					tristique quisque suspendisse habitant. Posuere metus vel viverra at
					viverra magna. Ullamcorper cursus sodales ut id elementum. Urna montes
					hac aliquam facilisis sit et sodales felis. Netus nibh ornare mi vel
					lobortis vitae. Nibh libero nunc pellentesque ac mauris tellus.
				</div>
				<div className='flex'>
					<img
						src={dummyimage}
						alt=''
					/>
					<img
						src={dummyimage}
						alt=''
					/>
					<img
						src={dummyimage}
						alt=''
					/>
				</div>
				<div className='flex justify-between'>
					<div className='text-lg font-bold'>Color Family:White</div>
					<div className='flex gap-5'>
						<img
							src={thumbup}
							alt=''
						/>
						0
						<img
							src={thumbdown}
							alt=''
						/>
						0
					</div>
				</div>
			</div>

			<hr />
		</>
	);
};
