import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import { Link } from 'react-router-dom';

export const CancelOrder = () => {
	return (
		<>
			<div className='px-5 sm:px-10 md:px-20 lg:px-40 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Request Cancellation
			</div>
			<div className='flex flex-col mx-5 sm:mx-10 md:mx-20 lg:mx-40 text-black gap-10'>
				<div className='flex flex-col my-5 gap-5 p-5 h-fit w-full'>
					<div className='border-2 rounded-lg p-5 flex flex-col md:flex-row lg:flex-row items-center'>
						<div>
							<label>
								<input
									type='checkbox'
									className='checkbox border-black rounded-lg'
								/>
							</label>
						</div>
						<div>
							<img
								src={dummyimg}
								alt=''
								className='w-20 h-20 object-cover'
							/>
						</div>
						<div className='flex flex-col justify-between'>
							<p>
								M10 & M90 & i12 black Double Wireless 2 Airpods Bluetooth Hand
								Free with Power Bank Earphones White bluetooth ear phone for all
								cell phones
							</p>
							<p>Qty: 1</p>
						</div>
						<div>
							{/* select reason */}
							<select
								name='reason'
								id='reason'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-20 sm:w-32 lg:w-80'
							>
								<option value=''>Select Reason</option>
								<option value=''>Select Reason</option>
								<option value=''>Select Reason</option>
							</select>
						</div>
					</div>
				</div>
				<div className='flex flex-col my-5 gap-5 p-5 h-fit w-full'>
					<div className='border-2 rounded-lg p-5 flex justify-start flex-col gap-5'>
						<div className='text-xl font-medium'>Cancellation Policy</div>
						<div>
							<div>
								Lorem ipsum dolor sit amet consectetur. Luctus ut sit et at
								volutpat sodales pellentesque a congue. Varius et mi vulputate
								id. Placerat gravida id blandit lacus massa sit. Mauris in vel
								vitae erat in malesuada odio id. Tincidunt ut nec lectus lorem
								tempus. Venenatis sed vitae sagittis cursus ipsum amet semper.
								Suspendisse eget pellentesque ullamcorper semper lacus vehicula
								netus. Morbi turpis blandit orci molestie id cras neque. Ipsum
								sed fermentum nulla vel lacinia diam volutpat. Mauris elementum
								condimentum feugiat feugiat ut nunc ultrices diam amet. Vitae
								ante et sem vitae purus nunc id.
							</div>
						</div>
						<div className='flex gap-5'>
							<label>
								<input
									type='checkbox'
									className='checkbox border-black rounded-lg'
								/>
							</label>
							<span>I have read and agree to the cancellation policy</span>
						</div>
					</div>
				</div>
				<div className='flex justify-end'>
					<Link to='/cancel-order/confirm'>
						<button className='bg-[#FFD700] text-black py-3 px-5 sm:px-8 lg:px-10 rounded-lg'>
							Submit
						</button>
					</Link>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
