import React from 'react';
import { Card } from './Card';
import { Link } from 'react-router-dom';

export const FlashSale = () => {
	return (
		<div className='flex flex-col sm:flex-col md:flex-col my-5 sm:mx-4 '>
			<div className='text-black text-2xl font-bold bg-[#ffd700] py-5 md:mx-40 px-2'>
				Flash Sale
			</div>
			<div className='flex flex-wrap gap-4 my-3 justify-center'>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
				<Link to='/product-detail'>
					<Card />
				</Link>
			</div>
		</div>
	);
};
