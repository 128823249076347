import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import bank from '../../assets/images/bank.png';
import cashondelivery from '../../assets/images/cashondelivery.png';
import easypaisa from '../../assets/images/easypaisa.png';
import jazzcash from '../../assets/images/jazz.png';
import wallet from '../../assets/images/mywallet.png';
import { AddAddress } from './AddAddress';
import { Furtherproced } from './Furtherproced';

export const CheckOut = () => {
	return (
		<>
			<div className='px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Confirm Your Order
			</div>

			<div className='min-h-lvh flex flex-col md:flex-row mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 text-black justify-center gap-6 md:gap-10 '>
				<div className='flex flex-col md:flex-col my-6 gap-5 p-5 h-fit w-full md:w-2/3'>
					<div className='border-2 rounded-lg p-5'>
						<div className='flex flex-col gap-5'>
							<h1 className='text-lg font-bold text-black'>Shipping Address</h1>
							<AddAddress />
						</div>
					</div>

					<div className='flex flex-col gap-5 border-2 rounded-lg p-5 text-black'>
						<div className='form-control flex'>
							<label className='label cursor-pointer'>
								<input
									type='radio'
									name='radio-10'
									className='radio border-black rounded-lg'
									checked
								/>
								<img
									src={cashondelivery}
									alt=''
								/>
							</label>
						</div>
						<div className='form-control'>
							<label className='label cursor-pointer'>
								<input
									type='radio'
									name='radio-10'
									className='radio border-black rounded-lg'
									checked
								/>
								<img
									src={wallet}
									alt=''
								/>
							</label>
						</div>
						<div className='form-control'>
							<label className='label cursor-pointer'>
								<input
									type='radio'
									name='radio-10'
									className='radio border-black rounded-lg'
									checked
								/>
								<img
									src={bank}
									alt=''
								/>
							</label>
						</div>
						<div className='form-control'>
							<label className='label cursor-pointer'>
								<input
									type='radio'
									name='radio-10'
									className='radio border-black rounded-lg'
									checked
								/>
								<img
									src={easypaisa}
									alt=''
								/>
							</label>
						</div>
						<div className='form-control'>
							<label className='label cursor-pointer'>
								<input
									type='radio'
									name='radio-10'
									className='radio border-black rounded-lg'
									checked
								/>
								<img
									src={jazzcash}
									alt=''
								/>
							</label>
						</div>
					</div>

					<div className='flex items-center  gap-10'>
						<div className='overflow-x-auto '>
							<table className='table text-black border-2 rounded-lg'>
								{/* head */}
								<thead>
									<tr className='text-black'>
										<th>
											<label>
												<input
													type='checkbox'
													className='checkbox'
												/>
											</label>
										</th>
										<th></th>
										<th>sr#</th>
										<th>Product Name</th>
										<th>Price</th>
										<th>Quantity</th>
										<th>
											<div className='nav-heart'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													className='inline-block w-8 h-8 stroke-current'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
													></path>
												</svg>
											</div>
										</th>
										<th>
											<svg
												width='37'
												height='37'
												viewBox='0 0 37 37'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
													fill='black'
												/>
											</svg>
										</th>
									</tr>
								</thead>
								<tbody>
									{/* row 1 */}
									<tr>
										<th>
											<label>
												<input
													type='checkbox'
													className='checkbox'
												/>
											</label>
										</th>
										<td>
											<div className='flex items-center gap-3'>
												<div className='avatar'>
													<div className='mask mask-squircle w-12 h-12'>
														<img
															src={dummyimg}
															alt=''
														/>
													</div>
												</div>
											</div>
										</td>
										<td>1</td>
										<td>Product 1</td>
										<td>Rs. 277</td>
										<td>1</td>
									</tr>
								</tbody>
								{/* foot */}
								<tfoot>
									<tr className='text-black'>
										<th>total rs 277</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<div className='flex flex-col border-2 rounded-lg my-10 gap-5 p-5 h-fit w-full md:w-1/3'>
					<div>Order Summary</div>
					<div className='flex items-center justify-between gap-10'>
						<div>Subtotal (1 items) </div>
						<div> Rs. 277</div>
					</div>
					<div className='flex items-center justify-between gap-10'>
						<div>shipping fee </div>
						<div> Rs. 277</div>
					</div>
					{/* Total:                            Rs. 406 */}
					<div className='flex items-center justify-between gap-10'>
						<div>Total: </div>
						<div> Rs. 406</div>
					</div>
					<Furtherproced />
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
