import React from 'react';
import { Reviews } from './Reviews';
import ratingstar from '../../assets/images/rattingstar.png';
import star5 from '../../assets/images/5star.png';
import star4 from '../../assets/images/4star.png';
import star3 from '../../assets/images/3star.png';
import star2 from '../../assets/images/2star.png';
import star1 from '../../assets/images/1star.png';

export const Rating = () => {
	return (
		<>
			<div className='flex flex-col my-5 mx-40 '>
				<div className='text-black text-2xl font-bold bg-[#ffd700] py-5 px-5'>
					Ratings & Reviews
				</div>
				<div className='flex flex-col gap-10 my-3 text-black'>
					<div className='flex justify-between'>
						<div className=' flex flex-col px-10 gap-10'>
							<p className='text-5xl font-bold'> 4.0</p>
							<img
								src={ratingstar}
								alt=''
							/>
							<p className='text-lg'>3081 ratings</p>
						</div>
						<div className='flex-col flex'>
							<div className='flex items-center gap-5'>
								<div>
									<img
										src={star5}
										alt=''
									/>
								</div>
								<div>
									<progress
										className='progress progress-warning w-56'
										value='100'
										max='100'
									></progress>
								</div>
								<div>1913</div>
							</div>
							<div className='flex items-center gap-5'>
								<div>
									<img
										src={star4}
										alt=''
									/>
								</div>
								<div>
									<progress
										className='progress progress-warning w-56'
										value='70'
										max='100'
									></progress>
								</div>
								<div>913</div>
							</div>
							<div className='flex items-center gap-5'>
								<div>
									<img
										src={star3}
										alt=''
									/>
								</div>
								<div>
									<progress
										className='progress progress-warning w-56'
										value='40'
										max='100'
									></progress>
								</div>
								<div>193</div>
							</div>
							<div className='flex items-center gap-5'>
								<div>
									<img
										src={star2}
										alt=''
									/>
								</div>
								<div>
									<progress
										className='progress progress-warning w-56'
										value='20'
										max='100'
									></progress>
								</div>
								<div>13</div>
							</div>
							<div className='flex items-center gap-5'>
								<div>
									<img
										src={star1}
										alt=''
									/>
								</div>
								<div>
									<progress
										className='progress progress-warning w-56'
										value='2'
										max='100'
									></progress>
								</div>
								<div>10</div>
							</div>
						</div>
					</div>
					<hr />
					<div className='flex flex-col gap-5'>
						<div className='flex justify-start gap-10'>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>All (91)</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								Pic review (34)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								Local review (66)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								5 stars (66)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								4 stars (11)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								3 stars (4)
							</div>
						</div>
						<div className='flex justify-start gap-10'>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								2 stars (3)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								1 star (7)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								sound quality is good (11)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								product is good (7)
							</div>
							<div className='py-2 px-2 bg-slate-400 rounded-2xl'>
								fast delivery (3)
							</div>
						</div>
					</div>
					<hr />
					<div className='flex flex-col gap-5'>
						<Reviews />
						<Reviews />
						<Reviews />
					</div>
				</div>

				<div className='flex items-center justify-center'>
					<button className='bg-[#ffd700] text-black font-bold py-3 px-6 rounded-lg w-80'>
						View More
					</button>
				</div>
			</div>
		</>
	);
};
