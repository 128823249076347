import React from 'react';

export const Footer = () => {
	return (
		<>
			<footer className='footer p-10 bg-white text-black mt-50 '>
				<nav>
					<h6 className='footer-title'>Customer Care</h6>
					<a className='link link-hover'>Help center</a>
					<a className='link link-hover'>How to buy</a>
					<a className='link link-hover'>Returns & Refunds</a>
					<a className='link link-hover'>Contact Us</a>
				</nav>
				<nav>
					<h6 className='footer-title'>Top Category</h6>
					<a className='link link-hover'>Men’s Fashion</a>
					<a className='link link-hover'>Women’s Fashion</a>
					<a className='link link-hover'>Electronic Accessories</a>
					<a className='link link-hover'>Health & Beauty</a>
				</nav>
				<nav>
					<h6 className='footer-title'>My Account</h6>
					<a className='link link-hover'>Dashboard</a>
					<a className='link link-hover'>My Orders</a>
					<a className='link link-hover'>Recent Orders</a>
					<a className='link link-hover'>Updated profile</a>
				</nav>
				<nav>
					<h6 className='footer-title'>Baazaar</h6>
					<a className='link link-hover'>About Us</a>
					<a className='link link-hover'>Digital Payments</a>
					<a className='link link-hover'>Terms & Conditions</a>
					<a className='link link-hover'>Privacy Policy</a>
				</nav>
			</footer>

			<div className='flex flex-col justify-center items-center bg-white text-black'>
				<div className='text-black text-2xl font-bold'>Follow Us</div>
				<div className='flex'>
					<div>1</div>
					<div>1</div>
					<div>1</div>
					<div>1</div>
				</div>
			</div>
		</>
	);
};
