import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';

export const AboutUs = () => {
	return (
		<>
			{/* <div className='bg-[#ffd700] gap-4 flex flex-col justify-center text-center text-white min-h-96'>
				<h1 className='text-5xl font-bold'>Baazaar is</h1>
				<h1 className='text-5xl font-bold'>Leading Online</h1>
				<h1 className='text-5xl font-bold'> Marketplace</h1>
			</div> */}
			<div className='bg-[#ffd700] gap-4 flex flex-col justify-center text-center text-white min-h-96'>
				<h1 className='text-5xl font-bold sm:text-4xl md:text-6xl'>
					Baazaar is
				</h1>
				<h1 className='text-5xl font-bold sm:text-4xl md:text-6xl'>
					Leading Online
				</h1>
				<h1 className='text-5xl font-bold sm:text-4xl md:text-6xl'>
					Marketplace
				</h1>
			</div>

			<div className='text-black'>
				<h1 className='text-3xl font-bold text-center mt-10 '>About Us</h1>
				<p className=' text-left mt-10 mx-40 text-xl'>
					Welcome to Baazaar, your ultimate destination for all your online
					shopping needs. Founded with a vision to revolutionize the way people
					shop online, we strive to provide a seamless and delightful shopping
					experience to our valued customers.
				</p>

				<p className=' text-left mt-10 mx-40 text-xl'>
					At Baazaar, our mission is simple – to connect customers with the
					products they love, while offering unparalleled convenience,
					reliability, and affordability. We aim to create a platform where
					shoppers can explore a vast array of products from trusted brands, all
					in one place.
				</p>

				<p className=' text-left mt-10 mx-40 text-xl'>
					We are a passionate team of individuals driven by innovation and
					customer satisfaction. With years of experience in e-commerce,
					technology, and customer service, we work tirelessly to ensure that
					every aspect of your shopping journey with us is nothing short of
					exceptional.
				</p>

				<p className=' text-left mt-10 mx-40 text-xl'>
					We handpick every product to ensure quality, value, and relevance to
					our customers’ needs and preferences.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					Our website is designed with your convenience in mind, making it easy
					to navigate, search, and purchase products effortlessly.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					We prioritize the security of your personal and financial information,
					employing the latest encryption technologies to safeguard your
					transactions.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					Our dedicated support team is available around the clock to assist you
					with any queries, concerns, or assistance you may need.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					We are committed to reducing our environmental footprint and promoting
					sustainable practices throughout our operations. From eco-friendly
					packaging to partnering with environmentally conscious suppliers, we
					are continuously striving to make a positive impact on the planet.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					Join the baazaar family today and experience the convenience,
					reliability, and affordability that sets us apart from the rest.
					Whether you’re shopping for everyday essentials, the latest gadgets,
					or unique gifts, we’ve got you covered.
				</p>
				<p className=' text-left mt-10 mx-40 text-xl'>
					Thank you for choosing Baazaar – where shopping is always a pleasure!
				</p>
			</div>

			<CarouselBottem />
		</>
	);
};
