import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Shipping } from '../../components/Shipping/Shipping';

export const Shippingpage = () => {
	return (
		<>
			<Navbar />
			<Shipping />
			<Footer />
		</>
	);
};
