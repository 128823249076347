import React, { useState, useEffect } from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	sellerSignup,
	getsellerPackage,
	getqualityController,
} from '../../URLS/ApiEndpoint';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
	yourName: yup.string().required('Your name is required'),
	brandName: yup.string().required('Brand name is required'),
	yourEmail: yup
		.string()
		.email('Invalid email address')
		.required('Email is required'),
	selectQualityController: yup
		.string()
		.required('Select quality controller is required'),
	image: yup.string(),
	yourPhone: yup.string().required('Your phone is required'),
	selectCountry: yup.string(),
	selectRegion: yup.string(),
	officialAddress: yup.string().required('Official address is required'),
	productCategory: yup.string().required('Product category is required'),
	createPassword: yup.string().required('Create password is required'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('createPassword'), null], 'Passwords must match')
		.required('Confirm password is required'),
	package: yup.string().required('Package is required'),
});

export const SellerForms = () => {
	const [city, setCity] = useState('');
	const [country, setCountry] = useState('');
	const [packages, setPackages] = useState([]);
	const [qualityController, setQualityController] = useState([]);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const selectCountry = (val) => {
		setCountry(val);
	};
	const selectCity = (val) => {
		setCity(val);
	};
	const onSubmit = async (data, event) => {
		event.preventDefault();
		const updatedData = {
			...data,
			selectCountry: country,
			selectRegion: city,
		};

		try {
			const response = await fetch(`${sellerSignup}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(updatedData),
			});
			const responseData = await response.json();
			if (response.ok) {
				console.log('submitted successfully', responseData);
				toast.success('Submitted successfully');
				toast.warn('verification pending');
				reset();
				setCity('');
				setCountry('');
			} else {
				console.log('error signing up');
				toast.error('Error signing up');
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const fetchSellerpackage = async () => {
			const result = await fetch(getsellerPackage);
			const packages = await result.json();
			console.log('seller package', packages);
			setPackages(packages);
		};

		const fetchQualityController = async () => {
			const result = await fetch(getqualityController);
			const QC = await result.json();
			console.log('Quality controller ', QC);
			setQualityController(QC);
		};
		fetchSellerpackage();
		fetchQualityController();
	}, []);

	return (
		<>
			<div className='flex text-start min-h-lvh mx-5 sm:mx-10 md:mx-20 lg:mx-40 gap-10 text-black flex-col'>
				<ToastContainer />
				<form onSubmit={(e) => handleSubmit(onSubmit)(e)}>
					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						<div className='flex-col flex gap 5'>
							{/* your name */}
							<label className='my-5 text-lg'> Your Name</label>
							<input
								type='text'
								placeholder='Your name'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.yourName ? 'border-red-500' : ''
								}`}
								{...register('yourName')}
							/>
							{/* brand name */}
							<label className='my-5 text-lg'> Brand Name</label>
							<input
								type='text'
								placeholder='Brand name'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.brandName ? 'border-red-500' : ''
								}`}
								{...register('brandName')}
							/>
							{errors?.brandName && (
								<p className='text-red-500'>{errors?.brandName.message}</p>
							)}

							{/* your email */}
							<label className='my-5 text-lg'> Your Email</label>
							<input
								type='text'
								placeholder='Your email'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.yourEmail ? 'border-red-500' : ''
								}`}
								{...register('yourEmail')}
							/>

							{errors?.yourEmail && (
								<p className='text-red-500'>{errors?.yourEmail.message}</p>
							)}
							{/* select country */}
							<label className='my-5 text-lg'> select country</label>

							<CountryDropdown
								value={country}
								onChange={(val) => selectCountry(val)}
								name='selectCountry'
								required={true}
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								// Add this line to bind the selectCountry field
							/>
							{errors?.selectCountry && (
								<p className='text-red-500'>{errors?.selectCountry.message}</p>
							)}
							<label className='my-5 text-lg'>Enter Official Address</label>
							<textarea
								placeholder='Enter official address'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.officialAddress ? 'border-red-500' : ''
								}`}
								{...register('officialAddress')}
							/>
						</div>
						<div className='flex-col flex gap 5'>
							{/* select quality controller */}
							<label className='my-5 text-lg'>Select Quality Controller</label>
							<select
								id='selectQualityController'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.selectQualityController ? 'border-red-500' : ''
								}`}
								{...register('selectQualityController')}
							>
								<option value=''>Select Quality Controller</option>
								{qualityController.map((qc) => (
									<option
										key={qc._id}
										value={qc._id}
									>
										{qc.yourName}
									</option>
								))}
							</select>
							{errors?.selectQualityController && (
								<p className='text-red-500'>
									{errors?.selectQualityController.message}
								</p>
							)}

							{/* choose logo */}
							<label className='my-5 text-lg'>Choose Logo</label>
							<input
								type='file'
								name='image'
								placeholder='Choose logo'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.image ? 'border-red-500' : ''
								}`}
								{...register('image')}
							/>
							{errors?.image && (
								<p className='text-red-500'>{errors?.image.message}</p>
							)}

							{/* your phone */}
							<label className='my-5 text-lg'>Your Phone</label>
							<input
								type='text'
								placeholder='Your phone'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.yourPhone ? 'border-red-500' : ''
								}`}
								{...register('yourPhone')}
							/>
							{errors?.yourPhone && (
								<p className='text-red-500'>{errors?.yourPhone.message}</p>
							)}
							{/* select city */}
							<label className='my-5 text-lg'> Select Region:</label>

							<RegionDropdown
								country={country}
								required={true}
								value={city}
								onChange={(val) => selectCity(val)}
								name='selectRegion'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								// Add this line to bind the selectRegion field
							/>
							{errors?.selectRegion && (
								<p className='text-red-500'>{errors?.selectRegion.message}</p>
							)}
						</div>
					</div>
					{/* <div className='flex flex-col mx-5 sm:mx-10 md:mx-20 lg:mx-48'></div> */}
					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						<div className='flex-col flex gap-5'>
							{/* Product Category */}
							<label className='my-5 text-lg'>Product Category</label>
							<select
								id='productCategory'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.productCategory ? 'border-red-500' : ''
								}`}
								{...register('productCategory')}
							>
								<option value=''>Select Product Category</option>
								<option value='selling'> Selling</option>
								<option value='recycling'> recycling</option>
							</select>

							{/* Create Password */}
							<label className='my-5 text-lg'>Create Password</label>

							<input
								type='password'
								placeholder='Create password'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.createPassword ? 'border-red-500' : ''
								}`}
								{...register('createPassword')}
							/>
						</div>

						<div className='flex-col flex gap-5'>
							<label className='my-5 text-lg'>Package</label>
							<select
								id='productCategory'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 
								}`}
								{...register('package')}
							>
								{/* {
    "_id": "65f7bbd07da7736ffac27831",
    "title": "Sample Title",
    "storeCharges": "50",
    "packageName": "Sample Package",
    "minimumStoreCreated": "5",
    "type": "Sample Type",
    "__v": 0
} */}
								{packages.map((pack) => (
									<option
										key={pack._id}
										value={pack._id}
									>
										{pack.packageName}
									</option>
								))}
							</select>

							{errors?.package && (
								<p className='text-red-500'>{errors?.package.message}</p>
							)}

							<label className='my-5 text-lg'>Confirm Password</label>
							<input
								type='password'
								placeholder='Confirm password'
								className={`py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80 ${
									errors?.confirmPassword ? 'border-red-500' : ''
								}`}
								{...register('confirmPassword')}
							/>
						</div>
					</div>

					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						<button
							type='submit'
							className='mt-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-80'
						>
							Submit
						</button>
					</div>
				</form>
			</div>

			<CarouselBottem />
		</>
	);
};
