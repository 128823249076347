import React from 'react';
import { SBanner } from './SBanner';
import TabSeller from './MainTab';
export const SellerHome = () => {
	return (
		<>
			<SBanner />
			<TabSeller type={'seller'} />
		</>
	);
};
