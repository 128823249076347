// import React from 'react';
// import { CarouselBottem } from '../MainHome/CarouselBottem';

// export const OurQC = () => {
// 	return (
// 		<>
// 			<div className='mx-40 flex justify-center mt-20 gap-10 text-black min-h-lvh px-5'>
// 				<div className='flex flex-col border-2 rounded-xl max-h-52'>
// 					<h1 className='text-[#ffd700] font-bold text-lg mb-5 px-4'>
// 						verify Quality controller
// 					</h1>
// 					<div className='join px-4'>
// 						<input
// 							className='input input-bordered join-item w-96 bg-white'
// 							placeholder='Enter Ambassador ID/Email/Phone'
// 						/>
// 						<button className='btn join-item search-btn'> Verify</button>
// 						<div className='mt-5'>
// 							<hr />
// 						</div>
// 					</div>
// 				</div>
// 				<div className='flex flex-col border-2 rounded-xl max-h-80'>
// 					<h1 className='text-[#ffd700] font-bold text-lg mb-5 px-5'>
// 						Find Nearby Quality Controller
// 					</h1>
// 					<div className='flex flex-col gap-5 px-5'>
// 						<select
// 							name='Select Country'
// 							id=''
// 							className='mb-5 py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
// 						>
// 							<option value='Select Country'>Select Country</option>
// 							<option value='Select Country'>Select Country</option>
// 							<option value='Select Country'>Select Country</option>
// 							<option value='Select Country'>Select Country</option>
// 						</select>
// 						{/* select city */}
// 						<select
// 							name='Select City'
// 							id=''
// 							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black w-80'
// 						>
// 							<option value='Select City'>Select City</option>
// 							<option value='Select City'>Select City</option>
// 							<option value='Select City'>Select City</option>
// 							<option value='Select City'>Select City</option>
// 						</select>

// 						<select
// 							name='Category'
// 							id=''
// 							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black w-80'
// 						>
// 							<option value='Category'>Category</option>
// 							<option value='Category'>Category</option>
// 							<option value='Category'>Category</option>
// 							<option value='Category'>Category</option>
// 						</select>

// 						<button className='btn search-btn'> Search</button>
// 					</div>
// 				</div>
// 			</div>

// 			<CarouselBottem />
// 		</>
// 	);
// };

import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';

export const OurQC = () => {
	return (
		<>
			<div className='mx-4 sm:mx-4 md:mx-4 lg:mx-4 xl:mx-4 flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center mt-8 sm:mt-20 gap-5 text-black min-h-lvh px-2 sm:px-5 md:px-5 lg:px-5 xl:px-5'>
				<div className='flex flex-col border-2 rounded-xl sm:max-h-52 md:max-h-52 lg:max-h-52 xl:max-h-52'>
					<h1 className='text-[#ffd700] font-bold text-lg mb-3 px-2 sm:px-4 md:px-4 lg:px-4 xl:px-4'>
						Verify Quality controller
					</h1>
					<div className='join flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row items-start sm:items-center '>
						<input
							className='input input-bordered join-item w-full sm:w-96 bg-white mb-3 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0'
							placeholder='Enter Ambassador ID/Email/Phone'
						/>
						<button className='btn join-item search-btn w-full sm:w-auto'>
							Verify
						</button>
						<div className='mt-3 sm:mt-5'>
							<hr className='sm:hidden md:hidden lg:hidden xl:hidden' />
						</div>
					</div>
				</div>

				<div className='flex flex-col border-2 rounded-xl sm:max-h-80 md:max-h-80 lg:max-h-80 xl:max-h-80'>
					<h1 className='text-[#ffd700] font-bold text-lg mb-3 px-2 sm:px-5 md:px-5 lg:px-5 xl:px-5'>
						Find Nearby Quality Controller
					</h1>
					<div className='flex flex-col gap-3 sm:gap-5 md:gap-5 lg:gap-5 xl:gap-5 px-2 sm:px-5 md:px-5 lg:px-5 xl:px-5'>
						<select
							name='Select Country'
							id=''
							className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-5 rounded-lg border-2 bg-white border-black w-full sm:w-80'
						>
							<option value='Select Country'>Select Country</option>
							<option value='Select Country'>Select Country</option>
							<option value='Select Country'>Select Country</option>
							<option value='Select Country'>Select Country</option>
						</select>

						<select
							name='Select City'
							id=''
							className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-5 rounded-lg border-2 bg-white border-black w-full sm:w-80'
						>
							<option value='Select City'>Select City</option>
							<option value='Select City'>Select City</option>
							<option value='Select City'>Select City</option>
							<option value='Select City'>Select City</option>
						</select>

						<select
							name='Category'
							id=''
							className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-5 rounded-lg border-2 bg-white border-black w-full sm:w-80'
						>
							<option value='Category'>Category</option>
							<option value='Category'>Category</option>
							<option value='Category'>Category</option>
							<option value='Category'>Category</option>
						</select>

						<button className='btn search-btn w-full sm:w-auto'>Search</button>
					</div>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
