import React from 'react';
import aimage from '../../assets/images/a.png';
import qimage from '../../assets/images/q.png';

export const QA = ({ question, answer }) => {
	return (
		<div className='flex flex-col text-black gap-5'>
			<div className='flex flex-col md:flex-row items-center gap-5'>
				<div>
					<img
						src={qimage}
						alt=''
						// Adjust image size for responsiveness
					/>
				</div>
				<div className='font-bold text-lg md:text-xl'>{question}</div>
			</div>
			<div className='flex flex-col md:flex-row items-center gap-5'>
				<div>
					<img
						src={aimage}
						alt=''
						// Adjust image size for responsiveness
					/>
				</div>
				<div className='text-sm md:text-base'>{answer}</div>
			</div>
			<hr className='md:hidden' />{' '}
			{/* Hide horizontal rule on medium and larger screens */}
		</div>
	);
};
