export const endpoint = 'http://localhost:3000';
// export const endpoint = 'http://165.22.215.169:80';

// ecomerce end points
export const sellerSignup = `${endpoint}/sellers`;

// categories
export const getCategory = `${endpoint}/productcategory/selling`;
export const getRecCategory = `${endpoint}/productcategory/recycling`;

// detch seller package
export const getsellerPackage = `${endpoint}/admin/seller-package`;

// get quality controller

export const getqualityController = `${endpoint}/qualityControllers`;
