import React from 'react';
import Carousels from '../MainHome/Carousel';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import { FlashSale } from './FlashSale';
import { Categories } from './Categories';
import { ForYou } from './ForYou';
import { Banner } from './Banner';

export const MainHome = () => {
	return (
		<div>
			<Carousels />
			<CarouselBottem />
			<Banner />
			<FlashSale />
			<Categories />
			<ForYou />
			<CarouselBottem />
		</div>
	);
};
