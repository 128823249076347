import React from 'react';
import { SBanner } from '../SellerHome/SBanner';
import TabSeller from '../SellerHome/MainTab';

export const QualityController = () => {
	return (
		<>
			<SBanner />
			<TabSeller type={'qualitycontroller'} />
		</>
	);
};
