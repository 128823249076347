import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Favourite } from '../../components/favourite/Favourite';

export const FavouritePage = () => {
	return (
		<>
			<Navbar />
			<Favourite />
			<Footer />
		</>
	);
};
