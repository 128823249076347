import React from 'react';

export const AddAddress = () => {
	return (
		<>
			<button
				className='btn bg-white border-black w-full md:w-60'
				onClick={() => document.getElementById('add-address').showModal()}
			>
				+ Add New Address
			</button>
			<dialog
				id='add-address'
				className='modal'
			>
				<div className='modal-box w-11/12 max-w-5xl bg-white'>
					<form method='dialog'>
						{/* if there is a button in form, it will close the modal */}
						<button className='btn btn-sm btn-ghost absolute right-2 top-2 border-0'>
							✕
						</button>
					</form>
					<div className='font-bold text-lg flex justify-center items-center'>
						Add New Delivery Address
					</div>
					<form>
						<div className='flex flex-col md:flex-row justify-center gap-5 md:gap-14'>
							<div className='flex-col flex gap-5'>
								{/* full name */}
								<label className='mt-5'> Full Name</label>
								<input
									type='text'
									placeholder='Your Full Name'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* phone number */}
								<label className='mt-5'> Phone Number</label>
								<input
									type='text'
									placeholder='Your Phone Number'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* province */}
								<label className='mt-5'> Province</label>
								<input
									type='text'
									placeholder='Your Province'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* city */}
								<label className='mt-5'> City</label>
								<input
									type='text'
									placeholder='Your City'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* area  */}
								<label className='mt-5'> Area</label>
								<input
									type='text'
									placeholder='Your Area'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>
							</div>
							<div className='flex-col flex gap-5'>
								{/* address  */}
								<label className='mt-5'> Address</label>
								<input
									type='text'
									placeholder='Your Address'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* landmark */}
								<label className='mt-5'> Landmark{'(Optional)'}</label>
								<input
									type='text'
									placeholder='Your Landmark'
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								/>

								{/* select label */}
								<label className='mt-5'> Select Label</label>
								<select
									name='Home-office'
									id=''
									className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full md:w-80'
								>
									<option value='Select Label'>Select Label</option>
									<option value='Select Label'>Select Label</option>
									<option value='Select Label'>Select Label</option>
									<option value='Select Label'>Select Label</option>
								</select>

								{/* now add 2 check box for default address and save address */}
								{/* default address */}
								<label> Default Address(Optional)</label>
								<div className='flex flex-col items-start mt-2 gap-5 border-2 border-black p-5 rounded-xl'>
									<label>
										<input
											type='checkbox'
											className='checkbox border-black rounded-lg p-1'
										/>
										Default Address
									</label>

									<label>
										<input
											type='checkbox'
											className='checkbox border-black rounded-lg'
										/>
										Billing Address
									</label>

									<p>
										Your existing default address setting will be <br />
										replaced if you make some changes here.
									</p>
								</div>
							</div>
						</div>
					</form>
					<div className='modal-action'>
						<form method='dialog'>
							<button className='mt-5 py-4 px-2 rounded-lg  bg-[#ffd700] border-black w-full md:w-80'>
								Save
							</button>
						</form>
					</div>
				</div>
			</dialog>
		</>
	);
};
