import React from 'react';
import Banner from '../../assets/images/cuate.png';

export const SBanner = () => {
	return (
		<>
			{/* Sell on Pakistan’s #1 E-Commerce Website */}
			<div className='bg-[#ffd700] flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row items-center text-center min-h-96 md:min-h-96 lg:min-h-96 mb-5 md:justify-between lg:justify-between'>
				<div className='sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 gap-4 flex flex-col justify-center text-start mx-5 text-white'>
					<h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
						Sell on Pakistan’s
					</h1>
					<h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
						#1 E-Commerce
					</h1>
					<h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
						Website
					</h1>
				</div>
				<div>
					<img
						src={Banner}
						alt='Shoes'
						className='w-full h-auto'
					/>
				</div>
			</div>
		</>
	);
};
