import React from 'react';
import alert from '../../assets/images/alert-line.png';
import Return from '../../assets/images/return.png';
import Delivery from '../../assets/images/van.png';
import Tag from '../../assets/images/tag.png';

export const CarouselBottem = () => {
	return (
		<div className='flex flex-col sm:flex-row md:flex-row my-5 sm:mx-4 md:mx-40 justify-evenly gap-2 bg-yellow-400 bg-opacity-30 text-black py-4 px-5 rounded-xl'>
			<div className='flex items-center justify-center gap-1'>
				<img
					src={alert}
					alt=''
				/>
				<p>Safe Payment</p>
			</div>
			<div className='flex items-center justify-center gap-1'>
				<img
					src={Delivery}
					alt=''
				/>
				<p>Nationwide Delivery</p>
			</div>
			<div className='flex items-center justify-center gap-1'>
				<img
					src={Return}
					alt=''
				/>
				<p>Free & Easy Return</p>
			</div>
			<div className='flex items-center justify-center gap-1'>
				<img
					src={Tag}
					alt=''
				/>
				Best Price Guaranteed
			</div>
			<div className='flex items-center justify-center gap-1'>
				100% Authentic Products
			</div>
		</div>
	);
};
