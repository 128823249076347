import React from 'react';
import bluetoot from '../../assets/images/bluetooth.png';

export const CatCard = () => {
	return (
		<>
			<div className='card border-2 border-black p-4'>
				<div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row items-center justify-center'>
					<img
						src={bluetoot}
						alt='cat'
						className='w-full sm:w-40 md:w-40 lg:w-40 xl:w-40 h-40 object-cover rounded-lg mb-4 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 sm:mr-4 md:mr-4 lg:mr-4 xl:mr-4'
					/>
					<p className='text-lg font-bold text-black'>Studio Headphone</p>
				</div>
			</div>
		</>
	);
};
