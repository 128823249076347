import React from 'react';
import { CatCard } from './CatCard';

export const Categories = () => {
	return (
		<div className='flex flex-col sm:flex-col md:flex-col my-5 sm:mx-4 '>
			<div className='text-black text-2xl font-bold bg-[#ffd700] py-5 md:mx-40 px-2'>
				Categories
			</div>
			<div className='flex gap-4 my-3 flex-wrap items-center justify-evenly mx-32'>
				<CatCard />
				<CatCard />
				<CatCard />
			</div>
			<div className='flex gap-4 my-3 flex-wrap items-center justify-evenly mx-32'>
				<CatCard />
				<CatCard />
				<CatCard />
			</div>
			<div className='flex gap-4 my-3  flex-wrap items-center justify-evenly mx-32'>
				<CatCard />
				<CatCard />
				<CatCard />
			</div>
		</div>
	);
};
