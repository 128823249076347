import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import { Link } from 'react-router-dom';

export const Order = () => {
	return (
		<>
			<div className='px-5 sm:px-20 md:px-40 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Order Details
			</div>
			<div className='flex flex-col mx-5 sm:mx-20 md:mx-40 text-black gap-10'>
				<div className='flex flex-col my-5 gap-5 p-5 h-fit w-full sm:w-3/4 lg:w-full'>
					<div className='border-2 rounded-lg p-5 flex justify-start flex-col'>
						<p className='text-lg font-bold'>Your order number is 1234569874</p>
						<p>Placed on 6 mar 2024 10:10:60</p>
					</div>

					<div className='flex flex-col my-5 gap-5 p-5 h-fit w-full border-2 rounded-lg'>
						<div className='flex justify-between'>
							<div className='flex flex-col gap-1'>
								<p className='text-lg font-bold'>Package 1</p>
								<p>Sold by jsdgjsfh</p>
							</div>
							<div>Chat with seller</div>
						</div>
						<hr />
						<div className='flex justify-between'>
							<p className='text-xl font-medium'>
								Get by Thur 7 Mar - Sat 9 Mar
							</p>
							<p>Standard Delivery</p>
						</div>
						<div className='flex justify-center'>
							<ul className='timeline'>{/* Add timeline content */}</ul>
						</div>
						<div className='flex flex-col md:flex-row lg:flex-row justify-between'>
							<div className='flex flex-col md:flex-row md:gap-10 lg:flex-row items-center md:items-start lg:items-start'>
								<div>
									<img
										src={dummyimg}
										alt=''
										className='w-20 h-20 object-cover'
									/>
								</div>
								<div className='flex flex-col gap-5'>
									<div className='bg-white max-w-md'>
										M10 & M90 & i12 black Double Wireless 2 Airpods Bluetooth
										Hand Free with Power Bank Earphones White bluetooth ear
										phone for all cell phones
									</div>
									<div className='flex justify-between'>
										<p className='font-bold text-2xl'>PKR: 277</p>
										<p>Qty: 1</p>
									</div>
								</div>
							</div>
							<div className='text-xl'>
								<Link to={'/cancel-order'}>Cancel</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='flex flex-col sm:flex-row md:flex-row lg:flex-row mx-5 sm:mx-20 md:mx-40 text-black justify-center gap-20'>
				<div className='flex flex-col gap-5'>
					<div className='flex flex-col border-2 rounded-2xl max-w-fit p-8 gap-4 text-black'>
						<h1 className='font-bold text-xl'>Shipping Address</h1>
						<h1 className='font-bold text-lg'>Amna</h1>
						<p>12345678</p>
						<p>
							khdaksgdagskduagehmnbkaushdkajsbxsjbchkas <br />
							gdhxbjhfdkajsdlihejrhdcdudbjzdszbkjcdmcskjf
						</p>
						<p>Home</p>
					</div>
					<div className='flex flex-col border-2 rounded-2xl max-w-fit p-8 gap-4'>
						<h1 className='font-bold text-xl'>Billing Address</h1>
						<h1 className='font-bold text-lg'>Amna</h1>
						<p>12345678</p>
						<p>
							khdaksgdagskduagehmnbkaushdkajsbxsjbchkas <br />
							gdhxbjhfdkajsdlihejrhdcdudbjzdszbkjcdmcskjf
						</p>
						<p>Home</p>
					</div>
				</div>

				<div className='flex flex-col border-2 rounded-2xl min-w-5xl p-8 gap-4 min-h-fit'>
					<div className='font-bold text-xl'>Total Summary</div>
					<div className='flex justify-between text-xl font-bold'>
						<p>Subtotal</p>
						<p>Rs. 227</p>
					</div>

					<div className='flex justify-between text-xl font-bold'>
						<p>Delivery Fee</p>
						<p>Rs. 109</p>
					</div>

					<div className='flex justify-between text-xl font-bold'>
						<p>Cash Fee</p>
						<p>Rs. 10</p>
					</div>

					<div className='flex justify-between text-xl font-bold'>
						<p>Discount</p>
						<p>Rs. 109</p>{' '}
					</div>

					<div className='flex justify-between text-xl font-bold'>
						<p>Total</p>
						<p>PKR: 277</p>
					</div>
					<hr />

					<div className='flex justify-between text-xl font-bold'>
						Paid by Cash on delivery
					</div>
				</div>
			</div>
			<CarouselBottem />
		</>
	);
};
