import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { TopBar } from '../../components/navbar/TopBar';
import { Footer } from '../../components/Footer/Footer';

export const MainPage = () => {
	return (
		<>
			<div className='bg-white'>
				<Navbar />
				<TopBar />
				<Footer />
			</div>
		</>
	);
};
