import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';
import { Link } from 'react-router-dom';

export const Shipping = () => {
	return (
		<>
			<div className='sm:px-5 md:px-10 lg:px-20 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Thank You For Your Purchase
			</div>
			<div className='min-h-lvh flex flex-col sm:mx-5 md:mx-10 lg:mx-20 text-black gap-5'>
				<div className='border-2 rounded-lg p-5 flex justify-center'>
					<p>Your order number is 1234569874</p>
				</div>
				<h1 className='text-2xl font-bold'>Get By:</h1>
				<div className='flex flex-col gap-5 p-5 border-2 rounded-lg'>
					<div className='flex flex-col sm:flex-row justify-between items-center'>
						<div className='flex flex-col md:flex-row lg:flex-row items-center mb-3 sm:mb-0'>
							<div>
								<img
									src={dummyimg}
									alt=''
									className='w-20 h-20 object-cover'
								/>
							</div>
							<div className='ml-3'>
								M10 & M90 & i12 black Double Wireless 2 Airpods Bluetooth <br />
								Hand Free with Power Bank Earphones <br />
								White bluetooth ear phone for all cell phones
							</div>
						</div>
						<div className='text-sm sm:text-base'>Est 07 Mar - 09 Mar</div>
					</div>
					<div className='flex flex-col sm:flex-row justify-between'>
						<div className='mb-3 sm:mb-0'>
							To track the delivery of your order go to My AccountMy Order
						</div>
						<div>
							<button className='btn bg-white border-black w-36 sm:w-48'>
								View Order
							</button>
						</div>
					</div>
					<div className='flex text-3xl font-bold justify-end'>
						<p>Rs. 677</p>
					</div>
					<div className='flex justify-center'>
						<Link to='/order'>
							<button className='mt-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-80'>
								Continue Shopping
							</button>
						</Link>
					</div>
				</div>
			</div>
			<CarouselBottem />
		</>
	);
};
