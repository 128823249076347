import React from 'react';

import bannerimage from '../../assets/images/carosal.png';
import Slider from 'react-slick';

function Carousels() {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<div className='h-52 sm:h-64 xl:h-80 2xl:h-96 mb-52'>
			{/* <img
				src={bannerimage}
				alt=''
			/> */}
			<Slider
				{...settings}
				className='bg-[#ffd700]'
			>
				<div>
					<img
						src={bannerimage}
						alt=''
					/>
				</div>
				<div>
					<img
						src={bannerimage}
						alt=''
					/>
				</div>
				<div>
					<img
						src={bannerimage}
						alt=''
					/>
				</div>
				<div>
					<img
						src={bannerimage}
						alt=''
					/>
				</div>
			</Slider>
		</div>
	);
}

export default Carousels;
