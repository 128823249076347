import React, { useState } from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const schema = yup.object().shape({
	yourName: yup.string().required('Your name is required'),
	yourEmail: yup
		.string()
		.email('Invalid email address')
		.required('Email is required'),
	experianceStatus: yup.string().required('Experiance status is required'),
	servicePost: yup.string().required('Service post is required'),
	selectGender: yup.string(),
	chooseProfile: yup.string().required('Profile is required'),
	yourPhone: yup.string().required('Your phone is required'),
	youtubeVideoLink: yup.string().required('Youtube video link is required'),
	selectPackage: yup.string().required('Select package is required'),
	enterOfficalAddress: yup.string().required('Offical address is required'),
	heighestQualification: yup
		.string()
		.required('Heighest qualification is required'),
	generalSkills: yup.string().required('General skills is required'),
	previousExperience: yup.string().required('Previous experience is required'),
	uploadCnicImageFront: yup.mixed().required('Cnic image is required'),
	uploadCv: yup.mixed().required('Cv is required'),
	// uploadCv: yup.,
	bankAccount: yup.string().required('Bank account is required'),
	branchCode: yup.string().required('Brance code is required'),
	mobileAccount: yup.string().required('Mobile account is required'),
	registeration: yup.string().required('Registeration is required'),
	bussinessLogo: yup.string().required('Bussiness logo is required'),
	joiningAs: yup.string().required('Joining as is required'),
	specialSkills: yup.string().required('Special skills is required'),
	selectCountry: yup.string(),
	selectRegion: yup.string(),
	uploadCnicImageBack: yup.mixed().required('Cnic image is required'),
	bankAccountTittle: yup.string().required('Bank account tittle is required'),
	bankName: yup.string().required('Bank name is required'),
	mobileAccountTittle: yup
		.string()
		.required('Mobile account tittle is required'),
	describeYourSelf: yup.string().required('Description is required'),
	industryInstituteRegisteration: yup
		.string()
		.required('Registeration is required'),
	password: yup.string().required('Password is required'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match')
		.required('Confirm password is required'),
});

export const QCform = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const [country, setCountry] = useState('');
	const [city, setCity] = useState('');

	const selectCountry = (val) => {
		setCountry(val);
	};
	const selectCity = (val) => {
		setCity(val);
	};

	const onSubmit = (data) => {
		const updatedData = {
			...data,
			selectCountry: country,
			selectRegion: city,
		};
		console.log(' qc Form submitted');
		console.log(updatedData);
		reset();
	};

	return (
		<>
			<div className='flex text-start min-h-lvh mx-5 sm:mx-10 md:mx-20 lg:mx-40 gap-10 text-black flex-col '>
				<h1 className='text-2xl font-bold text-black sm:mx-10 md:mx-20 lg:mx-48my-5'>
					Basic info
				</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						{/* <h1 className='text-2xl font-bold text-black mx-44'>Basic info</h1> */}
						<div className='flex-col flex gap 5'>
							{/* your name */}
							<label className='my-5 text-lg'> your name</label>
							<input
								type='text'
								placeholder='Your name '
								name='yourName'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('yourName')}
							/>
							{errors.yourName && (
								<p className='text-red-500'>{errors.yourName.message}</p>
							)}

							{/* your email  */}
							<label className='my-5 text-lg'> your email</label>
							<input
								type='email'
								placeholder='Your email '
								name='yourEmail'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('yourEmail')}
							/>
							{errors.yourEmail && (
								<p className='text-red-500'>{errors.yourEmail.message}</p>
							)}

							{/* experiance status */}

							<label className='my-5 text-lg'> experiance status</label>

							<select
								name='experianceStatus'
								id='experianceStatus'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('experianceStatus')}
							>
								<option value='Beginner'>Beginner</option>
								<option value='Intermediate'>Intermediate</option>
								<option value='Expert'>Expert</option>
							</select>

							{errors.experianceStatus && (
								<p className='text-red-500'>
									{errors.experianceStatus.message}
								</p>
							)}

							{/* service post */}

							<label className='my-5 text-lg'> service post</label>
							<input
								type='text'
								placeholder='Your service post '
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								name='servicePost'
								{...register('servicePost')}
							/>
							{errors.servicePost && (
								<p className='text-red-500'>{errors.servicePost.message}</p>
							)}

							<label className='my-5 text-lg'> enter offical address</label>
							<input
								type='text'
								placeholder='Your offical address '
								name='enterOfficalAddress'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('enterOfficalAddress')}
							/>
							{errors.enterOfficalAddress && (
								<p className='text-red-500'>
									{errors.enterOfficalAddress.message}
								</p>
							)}
							{/* password  */}
							<label className='my-5 text-lg'> password</label>
							<input
								type='password'
								placeholder='Your password '
								name='password'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('password')}
							/>
							{errors.password && (
								<p className='text-red-500'>{errors.password.message}</p>
							)}
							{/* confirm password  */}
						</div>
						<div className='flex-col flex gap 5'>
							{/* Gender */}
							<label className='my-5 text-lg'> Select Gender</label>
							<select
								name='selectGender'
								id='selectGender'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('selectGender')}
							>
								<option value='male'>Male</option>

								<option value='Female'>Female</option>

								<option value='Other'>Other</option>
							</select>
							{errors.selectGender && (
								<p className='text-red-500'>{errors.selectGender.message}</p>
							)}

							<label className='my-5 text-lg'> choose Profile</label>
							<input
								type='file'
								id='chooseProfile'
								name='chooseProfile'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('chooseProfile')}
							/>
							{errors.chooseProfile && (
								<p className='text-red-500'>{errors.chooseProfile.message}</p>
							)}

							<label className='my-5 text-lg'> your phone</label>
							<input
								type='text'
								placeholder='Your phone '
								name='yourPhone'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('yourPhone')}
							/>

							{errors.yourPhone && (
								<p className='text-red-500'>{errors.yourPhone.message}</p>
							)}

							{/* yt video link */}
							<label className='my-5 text-lg'> youtube video link</label>
							<input
								type='text'
								placeholder='Your youtube video link '
								name='youtubeVideoLink'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('youtubeVideoLink')}
							/>
							{errors.youtubeVideoLink && (
								<p className='text-red-500'>
									{errors.youtubeVideoLink.message}
								</p>
							)}
							<label className='my-5 text-lg'> Select Gender</label>
							<select
								name='selectPackage'
								id='selectPackage'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('selectPackage')}
							>
								<option value='1'>1</option>

								<option value='2'>Fl2e</option>

								<option value='3'>Ot3her</option>
							</select>
							{errors.selectPackage && (
								<p className='text-red-500'>{errors.selectPackage.message}</p>
							)}
							<label className='my-5 text-lg'> confirm password</label>
							<input
								type='password'
								placeholder='Your confirm password '
								name='confirmPassword'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('confirmPassword')}
							/>
							{errors.confirmPassword && (
								<p className='text-red-500'>{errors.confirmPassword.message}</p>
							)}
						</div>
					</div>

					<h1 className='text-5xl font-bold text-black sm:m-10 md:m-20 lg:mx-48 lg:my-20'>
						Additional info
					</h1>
					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						<div className='flex-col flex gap 5'>
							{/* heighest qualification */}
							<label className='my-5 text-lg'> heighest qualification</label>
							<select
								name='heighestQualification'
								id='heighestQualification'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('heighestQualification')}
							>
								<option value='Under matric'>under matric</option>
								<option value='Matric'>Matric</option>
								<option value='Inter'>Inter</option>
								<option value='Bachlor'>Bachlor</option>
								<option value='Master'>Master</option>
							</select>

							{errors.heighestQualification && (
								<p className='text-red-500'>
									{errors.heighestQualification.message}
								</p>
							)}

							{/* general skills */}
							<label className='my-5 text-lg'> general skills</label>
							<input
								name='generalSkills'
								id='generalSkills'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('generalSkills')}
							/>

							{errors.generalSkills && (
								<p className='text-red-500'>{errors.generalSkills.message}</p>
							)}

							{/* previous experience */}
							<label className='my-5 text-lg'> previous experience</label>
							<input
								name='previousExperience'
								id='previousExperience'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('previousExperience')}
							/>

							{errors.previousExperience && (
								<p className='text-red-500'>
									{errors.previousExperience.message}
								</p>
							)}

							<label className='my-5 text-lg'> upload cnic image</label>
							<input
								type='file'
								placeholder='Your cnic image front '
								name='uploadCnicImageFront'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('uploadCnicImageFront')}
							/>
							{errors.uploadCnicImageFront && (
								<p className='text-red-500'>
									{errors.uploadCnicImageFront.message}
								</p>
							)}
							{/* uplaod cv */}
							<label className='my-5 text-lg'> upload cv</label>
							<input
								type='file'
								placeholder='Your cv '
								name='uploadCv'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('uploadCv')}
							/>

							{errors.uploadCv && (
								<p className='text-red-500'>{errors.uploadCv.message}</p>
							)}

							{/* bank account  */}
							<label className='my-5 text-lg'> bank account</label>
							<input
								type='text'
								name='bankAccount'
								placeholder='Your bank account '
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('bankAccount')}
							/>
							{errors.bankAccount && (
								<p className='text-red-500'>{errors.bankAccount.message}</p>
							)}

							{/* brance code  */}
							<label className='my-5 text-lg'> brance code</label>
							<input
								type='text'
								placeholder='Your brance code '
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('branchCode')}
							/>
							{errors.branchCode && (
								<p className='text-red-500'>{errors.branchCode.message}</p>
							)}

							{/* mobile account  */}
							<label className='my-5 text-lg'> mobile account</label>
							<input
								type='text'
								placeholder='Your mobile account '
								name='mobileAccount'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('mobileAccount')}
							/>
							{errors.mobileAccount && (
								<p className='text-red-500'>{errors.mobileAccount.message}</p>
							)}

							{/* registeration  */}
							<label className='my-5 text-lg'> registeration</label>
							<input
								type='text'
								placeholder='Your registeration '
								name='registeration'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('registeration')}
							/>
							{errors.registeration && (
								<p className='text-red-500'>{errors.registeration.message}</p>
							)}
							{/* bussiness logo  */}
							<label className='my-5 text-lg'> bussiness logo</label>
							<input
								type='file'
								placeholder='Your bussiness logo '
								name='bussinessLogo'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('bussinessLogo')}
							/>
							{errors.bussinessLogo && (
								<p className='text-red-500'>{errors.bussinessLogo.message}</p>
							)}
						</div>

						<div className='flex-col flex gap 5'>
							{/* joining as  */}

							{/* special skills */}
							<label className='my-5 text-lg'> special skills</label>
							<input
								name='specialSkills'
								id='specialSkills'
								className='py-4 px-5 rounded-lg border-2 bg-white border-black w-80'
								{...register('specialSkills')}
							/>

							{errors.specialSkills && (
								<p className='text-red-500'>{errors.specialSkills.message}</p>
							)}
							{/* country  */}
							<label className='my-5 text-lg'> country</label>
							<CountryDropdown
								value={country}
								onChange={(val) => selectCountry(val)}
								name='selectCountry'
								required={true}
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
							/>
							{errors.country && (
								<p className='text-red-500'>{errors.country.message}</p>
							)}

							{/* region  */}

							<label className='my-5 text-lg'> Select Region:</label>

							<RegionDropdown
								country={country}
								required={true}
								value={city}
								onChange={(val) => selectCity(val)}
								name='selectRegion'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
							/>

							{errors?.selectRegion && (
								<p className='text-red-500'>{errors?.selectRegion.message}</p>
							)}

							{/* cnic image */}
							<label className='my-5 text-lg'> upload cnic image</label>
							<input
								type='file'
								placeholder='Your cnic image back '
								name='uploadCnicImageBack'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('uploadCnicImageBack')}
							/>

							{errors.uploadCnicImageBack && (
								<p className='text-red-500'>
									{errors.uploadCnicImageBack.message}
								</p>
							)}

							{/* bank account tittle */}
							<label className='my-5 text-lg'> bank account tittle</label>
							<input
								type='text'
								placeholder='Your bank account tittle '
								name='bankAccountTittle'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('bankAccountTittle')}
							/>
							{errors.bankAccountTittle && (
								<p className='text-red-500'>
									{errors.bankAccountTittle.message}
								</p>
							)}
							{/* bank name  */}
							<label className='my-5 text-lg'> bank name</label>
							<input
								type='text'
								placeholder='Your bank name '
								name='bankName'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('bankName')}
							/>
							{errors.bankName && (
								<p className='text-red-500'>{errors.bankName.message}</p>
							)}

							{/* mobile accout tittle  */}

							<label className='my-5 text-lg'> mobile accout tittle</label>
							<input
								type='text'
								placeholder='Your mobile accout tittle '
								name='mobileAccountTittle'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('mobileAccountTittle')}
							/>
							{errors.mobileAccountTittle && (
								<p className='text-red-500'>
									{errors.mobileAccountTittle.message}
								</p>
							)}
							{/* describe your self
							 */}

							<label className='my-5 text-lg'> describe your self</label>
							<textarea
								type='text'
								placeholder='Describe your self '
								name='describeYourSelf'
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('describeYourSelf')}
							/>
							{errors.describeYourSelf && (
								<p className='text-red-500'>
									{errors.describeYourSelf.message}
								</p>
							)}

							{/* industry /institute registeration  */}
							<label className='my-5 text-lg'>
								{' '}
								industry /institute registeration
							</label>
							<input
								type='text'
								name='industryInstituteRegisteration'
								placeholder='Your industry /institute registeration '
								className='py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
								{...register('industryInstituteRegisteration')}
							/>
							{errors.industryInstituteRegisteration && (
								<p className='text-red-500'>
									{errors.industryInstituteRegisteration.message}
								</p>
							)}
						</div>
					</div>
					<div className='flex flex-col md:flex-row lg:flex-row justify-center sm:gap-10 md:gap-20 lg:gap-28'>
						<button
							type='submit'
							className='mt-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-80'
						>
							Submit
						</button>
					</div>
				</form>
			</div>

			<CarouselBottem />
		</>
	);
};
