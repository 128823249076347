import React from 'react';
import { QA } from './QA';

export const BuyerQA = () => {
	return (
		<>
			<div className='flex flex-col my-5 mx-40 '>
				<div className='text-black text-2xl font-bold bg-[#ffd700] py-5 px-5'>
					Buyers Questions & Answers
				</div>
				<div className='flex flex-col gap-10 my-3'>
					<QA
						question='What is the warranty of this product?'
						answer='The warranty of this product is 1 year.'
					/>
					<QA
						question='What is the warranty of this product?'
						answer='The warranty of this product is 1 year.'
					/>
					<QA
						question='What is the warranty of this product?'
						answer='The warranty of this product is 1 year.'
					/>
					<QA
						question='What is the warranty of this product?'
						answer='The warranty of this product is 1 year.'
					/>
				</div>

				<div className='flex items-center justify-center'>
					<button className='bg-[#ffd700] text-black font-bold py-3 px-6 rounded-lg w-80'>
						View More
					</button>
				</div>
			</div>
		</>
	);
};
