import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';

import { Footer } from '../../components/Footer/Footer';
import { Signup } from '../../components/Signup/Signup';

export const SignUpPage = () => {
	return (
		<>
			<Navbar />
			<Signup />
			<Footer />
		</>
	);
};
