// import React from 'react';
// import { CarouselBottem } from '../MainHome/CarouselBottem';
// import dummyimg from '../../assets/images/sampleimg.png';

// export const Favourite = () => {
// 	return (
// 		<>
// 			<div className='px-40 text-xl font-bold text-black bg-[#FFD700] py-5 '>
// 				Your Wishlist
// 			</div>

// 			<div className='min-h-lvh flex mx-40 text-black justify-center  gap-10 '>
// 				<div className='flex flex-col  my-10 gap-5 p-5 h-fit w-full'>
// 					<div className='border-2 rounded-lg p-5 flex justify-between'>
// 						<div className='flex gap-5'>
// 							<label>
// 								<input
// 									type='checkbox'
// 									className='checkbox'
// 								/>
// 							</label>
// 							<p>Add All To Cart</p>
// 						</div>
// 						<div>
// 							<svg
// 								width='37'
// 								height='37'
// 								viewBox='0 0 37 37'
// 								fill='none'
// 								xmlns='http://www.w3.org/2000/svg'
// 							>
// 								<path
// 									d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
// 									fill='black'
// 								/>
// 							</svg>
// 						</div>
// 					</div>
// 					<div className='flex items-center gap-10 '>
// 						<div className='overflow-x-auto w-full'>
// 							<table className='table text-black border-2 rounded-lg'>
// 								{/* head */}
// 								<thead>
// 									<tr className='text-black'>
// 										<th>
// 											<label>
// 												<input
// 													type='checkbox'
// 													className='checkbox'
// 												/>
// 											</label>
// 										</th>
// 										<th></th>
// 										<th>sr#</th>
// 										<th>Product Name</th>
// 										<th>Price</th>
// 										<th>Quantity</th>
// 										<th>
// 											<div className='nav-heart'>
// 												<svg
// 													xmlns='http://www.w3.org/2000/svg'
// 													fill='none'
// 													viewBox='0 0 24 24'
// 													className='inline-block w-8 h-8 stroke-current'
// 												>
// 													<path
// 														strokeLinecap='round'
// 														strokeLinejoin='round'
// 														strokeWidth='2'
// 														d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
// 													></path>
// 												</svg>
// 											</div>
// 										</th>
// 										<th>
// 											<svg
// 												width='37'
// 												height='37'
// 												viewBox='0 0 37 37'
// 												fill='none'
// 												xmlns='http://www.w3.org/2000/svg'
// 											>
// 												<path
// 													d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
// 													fill='black'
// 												/>
// 											</svg>
// 										</th>
// 									</tr>
// 								</thead>
// 								<tbody>
// 									{/* row 1 */}
// 									<tr>
// 										<th>
// 											<label>
// 												<input
// 													type='checkbox'
// 													className='checkbox'
// 												/>
// 											</label>
// 										</th>
// 										<td>
// 											<div className='flex items-center gap-3'>
// 												<div className='avatar'>
// 													<div className='mask mask-squircle w-12 h-12'>
// 														<img
// 															src={dummyimg}
// 															alt=''
// 														/>
// 													</div>
// 												</div>
// 											</div>
// 										</td>
// 										<td>1</td>
// 										<td>Product 1</td>
// 										<td>Rs. 277</td>
// 										<td>1</td>
// 									</tr>
// 								</tbody>
// 								{/* foot */}
// 								<tfoot>
// 									<tr className='text-black'>
// 										<th>total rs 277</th>
// 									</tr>
// 								</tfoot>
// 							</table>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			<CarouselBottem />
// 		</>
// 	);
// };

import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import dummyimg from '../../assets/images/sampleimg.png';

export const Favourite = () => {
	return (
		<>
			<div className='px-5 sm:px-10 md:px-20 lg:px-40 text-xl font-bold text-black bg-[#FFD700] py-5'>
				Your Wishlist
			</div>

			<div className='min-h-lvh flex flex-col sm:flex-row mx-5 sm:mx-10 md:mx-20 lg:mx-40 text-black justify-center gap-10'>
				<div className='flex flex-col my-10 gap-5 p-5 w-full'>
					<div className='border-2 rounded-lg p-5 flex justify-between'>
						<div className='flex gap-5'>
							<label>
								<input
									type='checkbox'
									className='checkbox'
								/>
							</label>
							<p>Add All To Cart</p>
						</div>
						<div>
							<svg
								width='37'
								height='37'
								viewBox='0 0 37 37'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
									fill='black'
								/>
							</svg>
						</div>
					</div>
					<div className='flex items-center gap-10'>
						<div className='overflow-x-auto w-full'>
							<table className='table text-black border-2 rounded-lg'>
								<thead>
									<tr className='text-black'>
										<th>
											<label>
												<input
													type='checkbox'
													className='checkbox'
												/>
											</label>
										</th>
										<th></th>
										<th>sr#</th>
										<th>Product Name</th>
										<th>Price</th>
										<th>Quantity</th>
										<th>
											<div className='nav-heart'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													className='inline-block w-8 h-8 stroke-current'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
													></path>
												</svg>
											</div>
										</th>
										<th>
											<svg
												width='37'
												height='37'
												viewBox='0 0 37 37'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M9.24967 29.2917C9.24967 30.1094 9.57452 30.8937 10.1528 31.4719C10.731 32.0501 11.5153 32.375 12.333 32.375H24.6663C25.4841 32.375 26.2684 32.0501 26.8466 31.4719C27.4248 30.8937 27.7497 30.1094 27.7497 29.2917V10.7917H9.24967V29.2917ZM12.333 13.875H24.6663V29.2917H12.333V13.875ZM23.8955 6.16667L22.3538 4.625H14.6455L13.1038 6.16667H7.70801V9.25H29.2913V6.16667H23.8955Z'
													fill='black'
												/>
											</svg>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>
											<label>
												<input
													type='checkbox'
													className='checkbox'
												/>
											</label>
										</th>
										<td>
											<div className='flex items-center gap-3'>
												<div className='avatar'>
													<div className='mask mask-squircle w-12 h-12'>
														<img
															src={dummyimg}
															alt=''
														/>
													</div>
												</div>
											</div>
										</td>
										<td>1</td>
										<td>Product 1</td>
										<td>Rs. 277</td>
										<td>1</td>
									</tr>
								</tbody>
								<tfoot>
									<tr className='text-black'>
										<th>Total Rs 277</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
