import React from 'react';
import { CarouselBottem } from '../MainHome/CarouselBottem';

export const Rack = () => {
	return (
		<>
			<div className='flex flex-col sm:flex-row md:flex-row md:items-start lg:items-start lg:flex-row xl:flex-row justify-center mt-10 sm:mt-20 gap-5 text-black mx-4 sm:mx-4 md:mx-4 lg:mx-4 xl:mx-4'>
				<div className='join flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row items-start sm:items-center  '>
					<input
						className='input input-bordered join-item w-full sm:w-96 bg-white mb-3 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0'
						placeholder='Search here'
					/>
					<button className='btn join-item search-btn w-full sm:w-auto sm:ml-2 md:ml-2 lg:ml-2 xl:ml-2'>
						Search
					</button>
					<div className='mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0'>
						<hr className='sm:hidden md:hidden lg:hidden xl:hidden' />
					</div>
				</div>
				<div className='flex flex-col'>
					<h1 className='text-[#ffd700] font-bold text-xl sm:text-3xl mb-3 sm:mb-5'>
						Apply Now
					</h1>
					<select
						name='Search job'
						id=''
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					>
						<option value='Search job'>Search job</option>
						<option value='Search job'>Search job</option>
						<option value='Search job'>Search job</option>
						<option value='Search job'>Search job</option>
					</select>
					<input
						type='text'
						placeholder='Your name'
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					/>
					<input
						type='text'
						placeholder='Your email'
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					/>
					<input
						type='text'
						placeholder='Your subject'
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					/>
					<input
						type='text'
						placeholder='Your phone'
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					/>

					{/* your msg */}
					<textarea
						name='msg'
						id=''
						cols='30'
						rows='5'
						placeholder='Your message'
						className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
					></textarea>
					<button className='mb-3 sm:mb-5 py-2 sm:py-4 px-2 sm:px-2 rounded-lg bg-[#ffd700] border-black w-full sm:w-80'>
						Submit
					</button>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
