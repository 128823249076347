import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { CancelOrder } from '../../components/Order/CancelOrder';

export const CancelOrderPage = () => {
	return (
		<>
			<Navbar />
			<CancelOrder />
			<Footer />
		</>
	);
};
