import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainPage } from './pages/Mainpage/MainPage';
import { LoginPage } from './pages/Login/LoginPage';
import { SignUpPage } from './pages/Signup/SignUpPage';
import { FavouritePage } from './pages/Favourite/FavouritePage';
import { CheckOutPage } from './pages/Checkout/CheckOutPage';
import { CartPage } from './pages/Cart/CartPage';
import { Shippingpage } from './pages/ShippingDetail/Shippingpage';
import { OrderPage } from './pages/OrderDetail/OrderPage';
import { CancelOrderPage } from './pages/CancelOrder/CancelOrderPage';
import { CancelConfirmPage } from './pages/CancelOrder/CancelConfirmPage';
import { ProductDetailPage } from './pages/ProductDetail/ProductDetailPage';

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<MainPage />}
					/>
					<Route
						path='/login'
						element={<LoginPage />}
					/>
					<Route
						path='/signup'
						element={<SignUpPage />}
					/>

					<Route
						path='/favourite'
						element={<FavouritePage />}
					/>
					<Route
						path='/checkout'
						element={<CheckOutPage />}
					/>
					<Route
						path='/cart'
						element={<CartPage />}
					/>
					<Route
						path='/shipping'
						element={<Shippingpage />}
					/>
					<Route
						path='/order'
						element={<OrderPage />}
					/>
					<Route
						path='/cancel-order'
						element={<CancelOrderPage />}
					/>
					<Route
						path='/cancel-order/confirm'
						element={<CancelConfirmPage />}
					/>
					<Route
						element={<ProductDetailPage />}
						path='/product-detail'
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
