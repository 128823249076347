// import React from 'react';
// import facebook from '../../assets/images/facebook.png';
// import google from '../../assets/images/google.png';
// import { CarouselBottem } from '../MainHome/CarouselBottem';
// import { Link } from 'react-router-dom';

// export const Login = () => {
// 	return (
// 		<>
// 			<div className='bg-yellow-400 bg-opacity-30 text-black min-h-lvh'>
// 				<div className='flex mx-40 my-10 py-10 justify-between'>
// 					<div className='text-2xl font-bold'>
// 						Welcome to Baazaar, Please Log In
// 					</div>
// 					<div>
// 						<Link to={'/signup'}> New member? Register here</Link>
// 					</div>
// 				</div>
// 				<div className='flex mx-40 mb-14 item-center justify-between bg-white px-20 rounded-2xl'>
// 					<div className='flex flex-col py-5'>
// 						<label>Phone Number Or Email: </label>
// 						<input
// 							type='email'
// 							placeholder='Please Enter Your phone number or email address'
// 							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black w-80'
// 						/>
// 						<label> Password:</label>
// 						<input
// 							type='text'
// 							placeholder='Enter your password '
// 							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black'
// 						/>

// 						<button className='flex flex-row items-end'>
// 							Reset Your Password
// 						</button>
// 					</div>
// 					<div className='flex flex-col py-5'>
// 						<button className='mt-5 py-4 px-2 rounded-lg  bg-[#ffd700] border-black w-80'>
// 							Login
// 						</button>
// 						<p className='mt-5'>or login wih</p>
// 						<div className='flex items-center justify-center gap-2'>
// 							{/* facebook */}
// 							<div
// 								className='flex items-center justify-center mt-5 py-4 px-2 rounded-lg  bg-[#6396FF] border-black w-40 gap-2'
// 								role='button'
// 							>
// 								<img
// 									src={facebook}
// 									alt=''
// 									width={30}
// 									height={30}
// 								/>
// 								<p>Facebook</p>
// 							</div>
// 							{/* google */}
// 							<div
// 								className='flex items-center justify-center mt-5 py-4 px-2 rounded-lg  bg-[#FF5B4A] border-black w-40 gap-2 '
// 								role='button'
// 							>
// 								<img
// 									src={google}
// 									alt=''
// 									width={30}
// 									height={30}
// 								/>
// 								<p>Google</p>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			<CarouselBottem />
// 		</>
// 	);
// };

import React from 'react';
import facebook from '../../assets/images/facebook.png';
import google from '../../assets/images/google.png';
import { CarouselBottem } from '../MainHome/CarouselBottem';
import { Link } from 'react-router-dom';

export const Login = () => {
	return (
		<>
			<div className='bg-yellow-400 bg-opacity-30 text-black min-h-lvh'>
				<div className='flex flex-col mx-5 sm:mx-10 md:mx-20 lg:mx-40 my-10 py-10 justify-between'>
					<div className='text-2xl font-bold'>
						Welcome to Baazaar, Please Log In
					</div>
					<div>
						<Link to={'/signup'}> New member? Register here</Link>
					</div>
				</div>
				<div className='flex flex-col mx-5 sm:mx-10 md:mx-20 md:flex-row lg:flex-row lg:mx-40 mb-14 item-center justify-between bg-white px-5 sm:px-10 md:px-20 lg:px-40 rounded-2xl'>
					<div className='flex flex-col py-5'>
						<label>Phone Number Or Email: </label>
						<input
							type='email'
							placeholder='Please Enter Your phone number or email address'
							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
						/>
						<label> Password:</label>
						<input
							type='text'
							placeholder='Enter your password '
							className='mb-5 py-4 px-2 rounded-lg border-2 bg-white border-black w-full sm:w-80'
						/>
						<button className='flex flex-row items-end'>
							Reset Your Password
						</button>
					</div>
					<div className='flex flex-col py-5'>
						<button className='mt-5 py-4 px-2 rounded-lg bg-[#ffd700] border-black w-full sm:w-80'>
							Login
						</button>
						<p className='mt-5'>or login with</p>
						<div className='flex items-center justify-center gap-2'>
							{/* facebook */}
							<div
								className='flex items-center justify-center mt-5 py-4 px-2 rounded-lg bg-[#6396FF] border-black w-full sm:w-40 gap-2'
								role='button'
							>
								<img
									src={facebook}
									alt=''
									width={30}
									height={30}
								/>
								<p>Facebook</p>
							</div>
							{/* google */}
							<div
								className='flex items-center justify-center mt-5 py-4 px-2 rounded-lg bg-[#FF5B4A] border-black w-full sm:w-40 gap-2'
								role='button'
							>
								<img
									src={google}
									alt=''
									width={30}
									height={30}
								/>
								<p>Google</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<CarouselBottem />
		</>
	);
};
