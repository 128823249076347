import React from 'react';
import { Navbar } from '../../components/navbar/Navbar';

import { Footer } from '../../components/Footer/Footer';
import { Login } from '../../components/Login/Login';

export const LoginPage = () => {
	return (
		<>
			<Navbar />
			<Login />
			<Footer />
		</>
	);
};
